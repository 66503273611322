import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { AutoComplete } from "primereact/autocomplete";

import { stateCodes } from "../../../constants/stateCodes";
import {
  normalizePhoneNumber,
  normalizeZipCode,
} from "../../../formUtils/normalizations";
import { validateZipCode } from "../../../formUtils/validations";
import { usePlacesWidget } from "react-google-autocomplete";

type pickupDetailsType = {
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactPhoneNumber: string;
  secondaryContactFirstName: string;
  secondaryContactLastName: string;
  secondaryContactPhoneNumber: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: { label: string; value: string };
  zipCode?: string;
};

export default function DestinationDetailsForm({
  initialValues,
  formName,
  requireAllFields,
  currentFormRef,
  handleEachStepNext,
}: any) {
  const [filteredStates, setFilteredStates] = useState<any>(null);

  const {ref: cityRef} = usePlacesWidget<any>({
    options: {
      types: ['(cities)'],
      fields: ['address_components','geometry'],
      componentRestrictions: {country: "us"}
    },
    onPlaceSelected: (place:any) => {
      formik.setFieldValue(
        'city', place.address_components.find((data: any) => data.types.some((placeData:any) => ['locality'].includes(placeData))) ? place.address_components.find((data: any) => data.types.some((placeData:any) => ['locality'].includes(placeData))).long_name:'',
      )
      formik.setFieldValue(
        'state', place.address_components.find((data: any) => data.types.some((placeData:any) => ['administrative_area_level_1'].includes(placeData))) ? 
          stateCodes.find((state: any) => state.label.toLowerCase() ===    
            place.address_components.find((data: any) => data.types.some((placeData:any) => ['administrative_area_level_1'].includes(placeData))).long_name.toLowerCase()) : '',
      )
      formik.setFieldValue(
        'zipCode', place.address_components.find((data: any) => data.types.some((placeData:any) => ['postal_code'].includes(placeData))) ? place.address_components.find((data: any) => data.types.some((placeData:any) => ['postal_code'].includes(placeData))).long_name: ''
      )
      formik.setFieldValue(
        'latitude', place.geometry.location.lat()
      )
      formik.setFieldValue(
        'longitude', place.geometry.location.lng()
      )
    }
  });

  const formik = useFormik({
    initialValues: initialValues
      ? {
          ...initialValues,
          primaryContactPhoneNumber: normalizePhoneNumber(
            initialValues?.primaryContactPhoneNumber?.replace("+1", "") || ""
          ),
          secondaryContactPhoneNumber: normalizePhoneNumber(
            initialValues?.secondaryContactPhoneNumber?.replace("+1", "") || ""
          ),
          state: initialValues?.state
            ? stateCodes?.find((x: any) => x.value === initialValues?.state)
            : undefined,
        }
      : {
          primaryContactFirstName: "",
          primaryContactLastName: "",
          primaryContactPhoneNumber: "",
          secondaryContactFirstName: "",
          secondaryContactLastName: "",
          secondaryContactPhoneNumber: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
          latitude: "",
          longitude:"",
        },
    validate: (data: pickupDetailsType) => {
      let errors: any = {};

      if (data.zipCode) {
        const validation = validateZipCode(data.zipCode);

        if (validation) {
          errors.zipCode = validation;
        }
      }

      if (requireAllFields) {
        if (!data.state) {
          errors.state = "This field is required.";
        }

        if (!data.city) {
          errors.city = "This field is required.";
        }

        // if (!data.primaryContactFirstName) {
        //   errors.primaryContactFirstName = "This field is required.";
        // }

        // if (!data.primaryContactLastName) {
        //   errors.primaryContactLastName = "This field is required.";
        // }
        // if (!data.primaryContactPhoneNumber) {
        //   errors.primaryContactPhoneNumber = "This field is required.";
        // }

        // if (!data.addressLine1) {
        //   errors.addressLine1 = "This field is required.";
        // }

        // if (!data.zipCode) {
        //   errors.zipCode = "This field is required.";
        // }
      }

      return errors;
    },
    onSubmit: (data: pickupDetailsType) => {
      handleEachStepNext(formName, {
        ...data,
        primaryContactPhoneNumber: data?.primaryContactPhoneNumber
          ? "+1" + data?.primaryContactPhoneNumber?.replaceAll("-", "")
          : "",
        secondaryContactPhoneNumber: data?.secondaryContactPhoneNumber
          ? "+1" + data?.secondaryContactPhoneNumber?.replaceAll("-", "")
          : "",
        state: data?.state?.value,
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    currentFormRef(formik);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type pickupDetailsKeyType =
    | "primaryContactFirstName"
    | "primaryContactLastName"
    | "primaryContactPhoneNumber"
    | "secondaryContactFirstName"
    | "secondaryContactLastName"
    | "secondaryContactPhoneNumber"
    | "addressLine1"
    | "addressLine2"
    | "city"
    | "state"
    | "zipCode";

  const isFormFieldValid = (name: pickupDetailsKeyType) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: pickupDetailsKeyType) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const searchState = (event: any) => {
    setTimeout(() => {
      let _filteredCountries;
      if (!event.query.trim().length) {
        _filteredCountries = [...stateCodes];
      } else {
        _filteredCountries = stateCodes.filter((state: any) => {
          return state.label
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredStates(_filteredCountries);
    }, 250);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ width: "100%", fontSize: "0.85rem" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="p-field"
          style={{
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <span className="p-float-label">
            <InputText
              id="primaryContactFirstName"
              name="primaryContactFirstName"
              value={formik.values.primaryContactFirstName}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("primaryContactFirstName"),
              })}
              style={{ width: "100%" }}
            />
            <label
              htmlFor="primaryContactFirstName"
              className={classNames({
                "p-error": isFormFieldValid("primaryContactFirstName"),
              })}
            >
              Primary Contact First Name
              {/* {requireAllFields ? "*" : ""} */}
            </label>
          </span>
          {getFormErrorMessage("primaryContactFirstName")}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <InputText
              id="primaryContactLastName"
              name="primaryContactLastName"
              value={formik.values.primaryContactLastName}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("primaryContactLastName"),
              })}
              style={{ width: "100%" }}
            />
            <label
              htmlFor="primaryContactLastName"
              className={classNames({
                "p-error": isFormFieldValid("primaryContactLastName"),
              })}
            >
              Primary Contact Last Name
              {/* {requireAllFields ? "*" : ""} */}
            </label>
          </span>
          {getFormErrorMessage("primaryContactLastName")}
        </div>
      </div>

      <div className="p-field" style={{ width: "100%" }}>
        <span
          className="p-float-label p-input-icon-left"
          style={{ width: "100%" }}
        >
          <i className="pi">+1</i>
          <InputText
            id="primaryContactPhoneNumber"
            name="primaryContactPhoneNumber"
            value={formik.values.primaryContactPhoneNumber}
            onChange={(e) => {
              e.target.value = normalizePhoneNumber(e.target.value);
              formik.handleChange(e);
            }}
            className={classNames({
              "p-invalid": isFormFieldValid("primaryContactPhoneNumber"),
            })}
            style={{ width: "100%" }}
          />
          <label
            htmlFor="primaryContactPhoneNumber"
            className={classNames({
              "p-error": isFormFieldValid("primaryContactPhoneNumber"),
            })}
            style={{
              marginLeft: formik.values.primaryContactPhoneNumber
                ? "-1.5rem"
                : 0,
            }}
          >
            Primary Contact Phone Number
            {/* {requireAllFields ? "*" : ""} */}
          </label>
        </span>
        {getFormErrorMessage("primaryContactPhoneNumber")}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="p-field"
          style={{
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <span className="p-float-label">
            <InputText
              id="secondaryContactFirstName"
              name="secondaryContactFirstName"
              value={formik.values.secondaryContactFirstName}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("secondaryContactFirstName"),
              })}
              style={{ width: "100%" }}
            />
            <label
              htmlFor="secondaryContactFirstName"
              className={classNames({
                "p-error": isFormFieldValid("secondaryContactFirstName"),
              })}
            >
              Alternate Contact First Name
            </label>
          </span>
          {getFormErrorMessage("secondaryContactFirstName")}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <InputText
              id="secondaryContactLastName"
              name="secondaryContactLastName"
              value={formik.values.secondaryContactLastName}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("secondaryContactLastName"),
              })}
              style={{ width: "100%" }}
            />
            <label
              htmlFor="secondaryContactLastName"
              className={classNames({
                "p-error": isFormFieldValid("secondaryContactLastName"),
              })}
            >
              Alternate Contact Last Name
            </label>
          </span>
          {getFormErrorMessage("secondaryContactLastName")}
        </div>
      </div>

      <div className="p-field" style={{ width: "100%" }}>
        <span
          className="p-float-label p-input-icon-left"
          style={{ width: "100%" }}
        >
          <i className="pi">+1</i>
          <InputText
            id="secondaryContactPhoneNumber"
            name="secondaryContactPhoneNumber"
            value={formik.values.secondaryContactPhoneNumber}
            onChange={(e) => {
              e.target.value = normalizePhoneNumber(e.target.value);
              formik.handleChange(e);
            }}
            className={classNames({
              "p-invalid": isFormFieldValid("secondaryContactPhoneNumber"),
            })}
            style={{ width: "100%" }}
          />
          <label
            htmlFor="secondaryContactPhoneNumber"
            className={classNames({
              "p-error": isFormFieldValid("secondaryContactPhoneNumber"),
            })}
            style={{
              marginLeft: formik.values.secondaryContactPhoneNumber
                ? "-1.5rem"
                : 0,
            }}
          >
            Alternate Contact Phone Number
          </label>
        </span>
        {getFormErrorMessage("secondaryContactPhoneNumber")}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="p-field"
          style={{ width: "100%", marginRight: "0.5rem" }}
        >
          <span className="p-float-label">
            <InputText
              id="addressLine1"
              name="addressLine1"
              value={formik.values.addressLine1}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("addressLine1"),
              })}
              style={{ width: "100%" }}
            />
            <label
              htmlFor="addressLine1"
              className={classNames({
                "p-error": isFormFieldValid("addressLine1"),
              })}
            >
              Address Line 1{/* {requireAllFields ? "*" : ""} */}
            </label>
          </span>
          {getFormErrorMessage("addressLine1")}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <InputText
              id="addressLine2"
              name="addressLine2"
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("addressLine2"),
              })}
              style={{ width: "100%" }}
            />
            <label
              htmlFor="addressLine2"
              className={classNames({
                "p-error": isFormFieldValid("addressLine2"),
              })}
            >
              Address Line 2
            </label>
          </span>
          {getFormErrorMessage("addressLine2")}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="p-field"
          style={{ width: "100%", marginRight: "0.5rem" }}
        >
          <span className="p-float-label">
            <InputText
              id="city"
              name="city"
              ref={cityRef}
              value={formik.values.city}
              onChange={formik.handleChange}
              placeholder=""
              className={classNames({
                "p-invalid": isFormFieldValid("city"),
              })}
              style={{ width: "100%", marginRight: "0.5rem" }}
            />
            <label
              htmlFor="city"
              className={classNames({
                "p-error": isFormFieldValid("city"),
              })}
            >
              City{requireAllFields ? "*" : ""}
            </label>
          </span>
          {getFormErrorMessage("city")}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem", marginRight: "0.5rem" }}
        >
          <span className="p-float-label">
            <AutoComplete
              id="state"
              name="state"
              field="label"
              value={formik.values.state}
              suggestions={filteredStates}
              completeMethod={searchState}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("state"),
              })}
              dropdown
              forceSelection
              style={{ width: "100%" }}
            />
            <label
              htmlFor="state"
              className={classNames({
                "p-error": isFormFieldValid("state"),
              })}
            >
              State{requireAllFields ? "*" : ""}
            </label>
          </span>
          {getFormErrorMessage("state")}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <InputText
              id="zipCode"
              name="zipCode"
              value={formik.values.zipCode}
              onChange={(e) => {
                e.target.value = normalizeZipCode(e.target.value);
                formik.handleChange(e);
              }}
              className={classNames({
                "p-invalid": isFormFieldValid("zipCode"),
              })}
              style={{ width: "100%" }}
            />
            <label
              htmlFor="zipCode"
              className={classNames({
                "p-error": isFormFieldValid("zipCode"),
              })}
            >
              Zip Code
              {/* {requireAllFields ? "*" : ""} */}
            </label>
          </span>
          {getFormErrorMessage("zipCode")}
        </div>
      </div>
    </form>
  );
}
