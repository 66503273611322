import * as alertType from "./types";

const types = {
  ...alertType,
};

export type AlertType = {
  show?: boolean,
  message?: string
  type?: 'error'| 'warning' | 'success' | 'info' | ''
};

const initialState: AlertType = {
  show: false,
  message: '',
  type: ''
};

export default function alertReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.SHOW_ALERT:
      return {
        show: true,
        message: action.payload.message,
        type: action.payload.type
      };
    case types.RESET_ALERT:
      return initialState;
    default:
      return state;
  }
}
