import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { UserType } from "../user/reducer";
import * as userType from "./types";
import { changePassword, loginUser, logoutUser } from "../../services/auth.service";
import * as alertType from "../alerts/types";

const types = {
  ...userType,
  ...alertType,
};

const parseRole = (userCredentials: any) => {
  return JSON.parse(userCredentials.data.user.reloadUserInfo.customAttributes).role
}

export const authenticateUser =
  ({
    emailAddress,
    password,
  }: UserType): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch): Promise<Boolean> => {
    const userCredentials = await loginUser({
      email: emailAddress || "",
      password: password || "",
    });
    // const state = getState();

    // const users = state.users.list;

    // const user = users.find(
    //   (x: UserType) =>
    //     x.emailAddress?.toLowerCase() === emailAddress?.toLowerCase() &&
    //     x.password === password
    // );

    if (userCredentials.status === 200) {
      if (parseRole(userCredentials) === 'customer') {
        const userPayload = {
          userId: userCredentials?.data?.user?.uid || "",
          emailAddress: userCredentials?.data?.user?.email || "",
          role: "CUSTOMER",
          theme: "light",
        };
  
        dispatch({
          type: types.AUTHENTICATE_USER_SUCCESS,
          payload: { user: userPayload },
        });
        dispatch({ type: types.RESET_ALERT  });
  
        return true;
      } else {
        dispatch({ type: types.AUTHENTICATE_USER_ERROR });
        dispatch({type: types.SHOW_ALERT, payload: {message: 'User not found.', type: 'error'}})
        return false;
      }
    } else {
      dispatch({ type: types.AUTHENTICATE_USER_ERROR });
      dispatch({
        type: types.SHOW_ALERT,
        payload: { message: userCredentials.statusText, type: "error" },
      });

      return false;
    }
  };

export const logout =
  (): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    const logoutResponse = await logoutUser();
    if (logoutResponse.status === 200) {
      dispatch({ type: types.LOGOUT_USER_SUCCESS });
    } else {
      dispatch({ type: types.AUTHENTICATE_USER_ERROR });
      dispatch({
        type: types.SHOW_ALERT,
        payload: { message: logoutResponse.statusText, type: "error" },
      });
    }
  };

export const changePasswordAction =
  (payload: any): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    const changePasswordResponse = await changePassword(payload)
    if (changePasswordResponse.status === 200) {
      dispatch({ type: types.PASSWORD_UPDATE_SUCCESS });
      dispatch({type: types.SHOW_ALERT, payload: {message: 'Password updated sucessfully', type: 'success'}})
    } else {
      dispatch({ type: types.PASSWORD_UPDATE_ERROR });
      dispatch({type: types.SHOW_ALERT, payload: {message: changePasswordResponse.statusText, type: 'error'}})
    }
  };
  

export const toggleDarkMode =
  (): ThunkAction<void, any, unknown, AnyAction> =>
  (dispatch): void => {
    dispatch({
      type: types.TOGGLE_DARK_MODE,
    });
  };
