import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";

import Confirmation from "../CreateServiceRequest/Confirmation";
import { fetchServiceRequest } from "../../../services/serviceRequests.service";
import { extractServiceRequestDataFromAPI } from "./extractServiceRequestDataFromAPI";
import { changeSelectedServiceRequest } from "../../../redux/serviceRequests/actions";

export default function ViewServiceRequest(props: any) {
  const [allServiceRequestData, setAllServiceRequestData] = useState<any>(null);
  const [serviceRequestData, setServiceRequestData] = useState<any>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const params = useParams<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setIsLoadingData(true);
      const serviceDetailsResponse = await fetchServiceRequest(params.id);
      if (serviceDetailsResponse.status === 200) {
        const formattedData: any = extractServiceRequestDataFromAPI(
          serviceDetailsResponse.data
        );

        setServiceRequestData(formattedData);
        setAllServiceRequestData(serviceDetailsResponse.data);
      }
      setIsLoadingData(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Paper
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          background: "none",
          boxShadow: "none",
        }}
      >
        <div></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {allServiceRequestData?.status === "PENDING_APPROVAL" && (
            <Button
              label={"Approve"}
              icon="pi pi-check-square"
              style={{
                backgroundColor: "#1769aa",
                borderColor: "#1769aa",
              }}
              onClick={() => {
                dispatch(
                  changeSelectedServiceRequest(allServiceRequestData.requestId)
                );
                props.history.push(
                  `/edit-service-request/${allServiceRequestData.requestId}`,
                  {
                    approvalProcess: true,
                  }
                );
              }}
            />
          )}

          <Button
            label={"Edit"}
            icon="pi pi-pencil"
            style={{
              backgroundColor: "#d65c49",
              borderColor: "#d65c49",
              marginLeft: "2rem",
            }}
            onClick={() => {
              dispatch(
                changeSelectedServiceRequest(allServiceRequestData.requestId)
              );
              props.history.push(
                `/edit-service-request/${allServiceRequestData.requestId}`
              );
            }}
          />
        </div>
      </Paper>

      {isLoadingData ? (
        <Skeleton width={50} />
      ) : (
        <Paper
          style={{
            width: "80%",
            padding: "2rem",
            margin: "1rem 0 2rem 0",
          }}
        >
          <Confirmation
            serviceRequestData={serviceRequestData}
            totalServiceCost={allServiceRequestData?.totalServiceCost}
            requireAllFields={["COMPLETED", "PENDING_APPROVAL"].includes(
              allServiceRequestData?.status
            )}
            flowName="View"
          />
        </Paper>
      )}
    </div>
  );
}
