import React from "react";
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "65vh",
};


const center = {
  lat: 37.431600,
  lng: -78.656900
};

function MyComponent() {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCr3-1Zp_KYKiQz5w3QCAEV_GsG5SuVzYI"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={7}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <>
          <Marker position={center}/>
        </>
      </GoogleMap>
    </LoadScript>
  )
}
export default React.memo(MyComponent);
