import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatMoney } from "accounting";
import {
  Grid,
  Paper,
  Typography,
  Link,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  alpha,
} from "@material-ui/core";
import {
  Add,
  GetApp,
  Search,
  Drafts,
  TurnedIn,
  HourglassEmpty,
  RestoreOutlined,
  Cached,
  Create,
  Check,
  FilterList,
} from "@material-ui/icons";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { changeSelectedServiceRequest } from "../../redux/serviceRequests/actions";
import { fetchDashboardData } from "../../redux/dashboard/actions";
import { Skeleton } from "@material-ui/lab";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { Menu } from "primereact/menu";
import { Checkbox } from "primereact/checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      backgroundColor: "#f8f9fa",
      padding: theme.spacing(2),
      "&:hover": {
        cursor: "pointer",
      },
    },
    notificationsCenter: {
      padding: theme.spacing(2),
    },
    paperHeaderStyle: {
      color: theme.palette.text.primary,
    },
    barchartHeaderStyle: {
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
    paperSubHeaderStyle: {
      color: theme.palette.text.primary,
      fontSize: "14px",
      fontWeight: "bold",
    },
    paperSubValues: {
      fontSize: "14px",
    },
    control: {
      padding: theme.spacing(2),
    },
    subvaluesContainer: {
      marginTop: "10px",
    },
    table: {},
    rowStyle: {
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    linkStyle: {
      fontSize: "0.9rem",
      "&:hover": {
        cursor: "pointer",
      },
    },
    timelineContainer: {
      position: "absolute",
      width: "80%",
      padding: theme.spacing(2),
      maxHeight: "90%",
      overflow: "scroll",
    },
    buttonStyle: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    addBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#388e3c",
      marginRight: "1rem",
      "&:hover": {
        backgroundColor: alpha("#388e3c", 0.75),
      },
    },
    refreshbtn: {
      borderRadius: "5px",
      color: "white",
      background: "#d65c49",
      marginRight: "1rem",
      padding: "0 0.5rem",
      "&:hover": {
        backgroundColor: alpha("#d65c49", 0.75),
      },
    },
    exportBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#1769aa",
      marginRight: "1rem",
      "&:hover": {
        backgroundColor: alpha("#1769aa", 0.75),
      },
    },
    filterBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      paddingLeft: "0.5rem",
      marginRight: "1rem",
      color: "white",
      background: "#ff9800",
      "&:hover": {
        backgroundColor: alpha("#ff9800", 0.75),
      },
    },
    buttonContainerStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
      padding: 0,
    },
    dataTableColumnStyle: {
      fontSize: "0.9rem",
      padding: "0.75rem !important",
      border: "1px solid #e9ecef !important",
    },
    dataTableHeaderStyle: {
      padding: "0.75rem !important",
      // border: "1px solid lightgrey !important",
      //backgroundColor: `${theme.palette.primary.main} !important`,
      //color: "#fff !important",
      whiteSpace: "nowrap",
    },
    dataTableRowStyle: {
      "&:nth-child(even)": {
        backgroundColor: "#BDBDBD !important",
      },
    },
    searchFieldStyle: {
      padding: "0.4rem 0 0.5rem 0.5rem",
    },
  })
);

const statusOptions = [
  { name: "Submitted", code: "SUBMITTED" },
  { name: "Pending Approval", code: "PENDING_APPROVAL" },
  { name: "Completed", code: "COMPLETED" },
];

export default function ServiceRequests({ history }: any) {
  const [selectedRow, setSelectedRow] = useState<any>();
  const dispatch = useDispatch();

  // const userDetails = useSelector((state: any) => state.user);
  const dashboardData = useSelector((state: any) => ({
    drafts: state?.dashboard?.drafts || 0,
    submitted: state?.dashboard?.submitted || 0,
    pendingApproval: state?.dashboard?.pendingApproval || 0,
    workOrdersInProgress: state?.dashboard?.workOrdersInProgress || 0,
  }));
  const serviceRequests = useSelector(
    (state: any) => state?.serviceRequests?.list
  );
  const totalServiceRequests = useSelector(
    (state: any) => state?.serviceRequests?.totalServiceRequests
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<any>([
    "SUBMITTED",
    "PENDING_APPROVAL",
  ]);
  const [filterByState, setFilterBy] = useState<any>(
    JSON.stringify({ status: selectedStatus })
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listOfServiceRequests, setServiceRequests] = useState<any>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const dataTableRef = useRef<any>();
  const menu = useRef<any>();
  const filterMenuRef = useRef<any>();

  // const [isTimeLineOpen, setIsTimeLineOpen] = useState(false);

  // Paginations states
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const onPageChage = (event: any) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    setRowsPerPage(event.rows);
  };

  const classes = useStyles();

  useEffect(() => {
    (async function fetchDashboard() {
      setLoading(true);
      await dispatch(
        fetchDashboardData(
          searchText,
          currentPage || 1,
          rowsPerPage,
          filterByState
        )
      );
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    if (serviceRequests) {
      setServiceRequests(serviceRequests);
    }
  }, [serviceRequests]);

  /**
   * on search event of filter data table.
   */
  const searchEvent = async () => {
    setLoading(true);
    await dispatch(
      fetchDashboardData(searchText, currentPage, rowsPerPage, filterByState)
    );
    setLoading(false);
  };

  const filterServiceRequest = async (statusValue: string) => {
    setLoading(true);
    setSelectedStatus([statusValue]);
    const filterByClone = JSON.stringify({ status: [statusValue] });
    setFilterBy(filterByClone);
    await dispatch(
      fetchDashboardData(searchText, currentPage, rowsPerPage, filterByClone)
    );
    setLoading(false);
  };

  const filterBy = async (e: any) => {
    let selectedStatusClone = [...selectedStatus];
    if (e.checked) selectedStatusClone.push(e.value);
    else selectedStatusClone.splice(selectedStatusClone.indexOf(e.value), 1);

    setSelectedStatus(selectedStatusClone);

    const filterByClone = JSON.stringify({
      [e.target.id]: selectedStatusClone,
    });
    setFilterBy(filterByClone);

    setLoading(true);
    await dispatch(
      fetchDashboardData(searchText, currentPage, rowsPerPage, filterByClone)
    );
    setLoading(false);
  };
  /**
   * Export as csv
   */
  const exportCSV = () => {
    if (dataTableRef && dataTableRef.current) {
      dataTableRef.current.exportCSV();
    }
  };

  const exportColumns = listOfServiceRequests?.[0]
    ? Object.keys(listOfServiceRequests?.[0]).reduce((a: any, c: string) => {
        const result = c?.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        a.push({
          title: finalResult,
          dataKey: c,
        });

        return a;
      }, [])
    : [];

  /**
   * Export as pdf
   */
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, listOfServiceRequests);
        doc.save("serviceRequests.pdf");
      });
    });
  };

  const saveAsExcelFile = (buffer: any, fileName: string) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  /**
   * Export as excel
   */
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(listOfServiceRequests);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "serviceRequests");
    });
  };

  const statusColor = (rowData:any) => {
      let color = "#388e3c";

      if (rowData.code === "SUBMITTED") {
        color = "#f7773c";
      }

      if (rowData.code === "PENDING_APPROVAL") {
        color = "#d65c49";
      }

      return (
        <span
          style={{
            padding: "0.5rem",
            backgroundColor: color,
            fontSize: "0.85rem",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          {rowData.name}
        </span>
      );
  }

  /**
   * Header template for the service request data table
   */
  const headerServiceRequestTable = (
    <Grid container>
      <Grid item sm={6} md={6}>
        <div className={classes.buttonContainerStyle}>
          <Tooltip title="New Service Request">
            <IconButton
              color="primary"
              aria-label="new-service-request"
              size="small"
              onClick={() => {
                dispatch(changeSelectedServiceRequest(null));
                history.push("/create-new-order");
              }}
              className={classes.addBtn}
            >
              <Add />
              <Typography>New</Typography>
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit Service Request">
            <IconButton
              color="primary"
              aria-label="edit-service-request"
              size="small"
              onClick={() => {
                dispatch(changeSelectedServiceRequest(selectedRow.requestId));
                history.push(`/edit-service-request/${selectedRow.requestId}`);
              }}
              className={classes.refreshbtn}
              style={{
                padding: "0.25rem",
              }}
              disabled={
                !(
                  selectedRow &&
                  ["SUBMITTED", "PENDING_APPROVAL", "COMPLETED"].includes(
                    selectedRow?.status
                  )
                )
              }
            >
              <Create />
              <Typography>Edit</Typography>
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit Service Request">
            <IconButton
              color="primary"
              aria-label="edit-service-request"
              size="small"
              onClick={() => {
                dispatch(changeSelectedServiceRequest(selectedRow.requestId));
                history.push(`/edit-service-request/${selectedRow.requestId}`, {
                  approvalProcess: true,
                });
              }}
              className={classes.exportBtn}
              disabled={
                !(
                  selectedRow &&
                  ["PENDING_APPROVAL"].includes(selectedRow?.status)
                )
              }
            >
              <Check />
              <Typography>Approve</Typography>
            </IconButton>
          </Tooltip>
        </div>
      </Grid>

      <Grid
        item
        sm={6}
        md={6}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Tooltip title="Refresh Data">
          <IconButton
            color="primary"
            aria-label="refresh"
            size="small"
            onClick={searchEvent}
            className={classes.refreshbtn}
          >
            <Cached />
          </IconButton>
        </Tooltip>

        <Tooltip title="Filter">
          <IconButton
            color="primary"
            aria-label="export"
            size="small"
            onClick={(event) => filterMenuRef.current.toggle(event)}
            className={classes.filterBtn}
          >
            <FilterList />
            <Typography>Filter</Typography>
          </IconButton>
        </Tooltip>

        <Tooltip title="Export">
          <IconButton
            color="primary"
            aria-label="export"
            size="small"
            onClick={(event) => menu.current.toggle(event)}
            className={classes.exportBtn}
          >
            <GetApp />
            <Typography>Export</Typography>
          </IconButton>
        </Tooltip>

        <Menu
          ref={filterMenuRef}
          popup
          id="filtermenu"
          model={[
            {
              template: () => {
                return (
                  <ul className="p-menu-list p-reset" role="menu">
                    {statusOptions &&
                      statusOptions.map((option: any, index: number) => (
                        <li className="p-menuitem" role="none" key={index}>
                          <a className="p-menuitem-link">
                            <span className="p-menuitem-icon">
                              <Checkbox 
                                id="status"
                                value={option.code}
                                onChange={filterBy}
                                checked={selectedStatus.includes(option.code)}
                              />
                            </span>
                            <span className="p-menuitem-text" style={{marginLeft: '10px'}}>{statusColor(option)}</span>
                          </a>
                        </li>
                      ))}
                  </ul>
                );
              },
            },
          ]}
        />

        <Menu
          model={[
            {
              label: "Export as CSV",
              icon: "pi pi-file-o",
              command: () => exportCSV(),
            },
            {
              label: "Export as Excel",
              icon: "pi pi-file-excel",
              command: () => exportExcel(),
            },
            {
              label: "Export as PDF",
              icon: "pi pi-file-pdf",
              command: () => exportPdf(),
            },
          ]}
          popup
          ref={menu}
          id="popup_menu"
        />

        <TextField
          placeholder="Search…"
          variant="outlined"
          onChange={(event: any) => setSearchText(event.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              searchEvent();
            }
          }}
          inputProps={{
            className: classes.searchFieldStyle,
          }}
          InputProps={{
            style: {
              padding: 0,
            },
            endAdornment: (
              <InputAdornment position="end" style={{ padding: 0, margin: 0 }}>
                <IconButton
                  onClick={searchEvent}
                  style={{
                    backgroundColor: "#388e3c",
                    padding: "0.25rem",
                    margin: 0,
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  /**
   * Table content of the service request id
   * @param row
   * @returns
   */
  const requestIDBody = (row: any) => (
    <Link
      className={classes.linkStyle}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        dispatch(changeSelectedServiceRequest(row.requestId));

        history.push(`/view-service-request/${row.requestId}`);
      }}
    >
      {row.requestId}
    </Link>
  );

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={3}>
        <Paper
          className={classes.paper}
          style={{ cursor: "auto" }}
          onClick={(event: any) => filterServiceRequest("DRAFT")}
        >
          <Typography className={classes.paperHeaderStyle} variant="subtitle2">
            In Draft
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {loading ? <Skeleton width={50} /> : dashboardData?.drafts}
            </Typography>
            <Drafts style={{ fontSize: "2rem", color: "#1769aa" }} />
          </div>
        </Paper>
      </Grid>
      <Grid item sm={3}>
        <Paper
          className={classes.paper}
          onClick={(event: any) => filterServiceRequest("SUBMITTED")}
        >
          <Typography className={classes.paperHeaderStyle} variant="subtitle2">
            Submitted
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {loading ? <Skeleton width={50} /> : dashboardData?.submitted}
            </Typography>
            <TurnedIn style={{ fontSize: "2rem", color: "#d65c49" }} />
          </div>
        </Paper>
      </Grid>

      <Grid item sm={3}>
        <Paper
          className={classes.paper}
          onClick={(event: any) => filterServiceRequest("PENDING_APPROVAL")}
        >
          <Typography className={classes.paperHeaderStyle} variant="subtitle2">
            Pending Approval
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {loading ? (
                <Skeleton width={50} />
              ) : (
                dashboardData?.pendingApproval
              )}
            </Typography>
            <HourglassEmpty style={{ fontSize: "2rem", color: "#388e3c" }} />
          </div>
        </Paper>
      </Grid>

      <Grid item sm={3}>
        <Paper
          className={classes.paper}
          onClick={(event: any) =>
            history.push("/order-history?status=in_progress")
          }
        >
          <Typography className={classes.paperHeaderStyle} variant="subtitle2">
            Work Orders In Progress
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {loading ? (
                <Skeleton width={50} />
              ) : (
                dashboardData?.workOrdersInProgress
              )}
            </Typography>
            <RestoreOutlined style={{ fontSize: "2rem", color: "#9b1de2" }} />
          </div>
        </Paper>
      </Grid>

      <Grid item sm={12}>
        <Paper style={{ padding: "0.25rem 1rem" }}>
          <Drawer
            anchor="right"
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            elevation={5}
            PaperProps={{ style: { position: "absolute", width: "486px" } }}
            BackdropProps={{ style: { position: "absolute" } }}
            ModalProps={{
              container: document.getElementById("drawer-container"),
              style: { position: "absolute", zIndex: 100 },
            }}
            SlideProps={{
              onEnter: (node: any) => {
                node.style.webkitTransform = "scaleX(0)";
                node.style.transform = "scaleX(0)";
                node.style.transformOrigin = "top right ";
              },
              onExiting: (node: any) => {
                node.style.webkitTransform = "scaleX(0)";
                node.style.transform = "scaleX(0)";
                node.style.transformOrigin = "top right ";
              },
            }}
          ></Drawer>

          <DataTable
            ref={dataTableRef}
            loading={loading}
            value={listOfServiceRequests}
            // selection={selectedDrivers}
            sortMode="multiple"
            // onSelectionChange={(e: any) => setSelectedDrivers(e.value)}
            dataKey="id"
            resizableColumns
            columnResizeMode="expand"
            tableStyle={{
              position: "relative",
              tableLayout: "auto",
              overflow: "auto",
              minWidth: "1000px",
              width: "100%",
            }}
            header={headerServiceRequestTable}
            selection={selectedRow}
            onSelectionChange={(e) => {
              setSelectedRow(e.value);
            }}
          >
            <Column
              selectionMode="single"
              style={{ width: "3rem" }}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            />
            <Column
              field="requestId"
              header="Request Id"
              sortable
              body={requestIDBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="customOrderId"
              header="Order Id"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="createdBy"
              header="Requested By"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="pickupLocation"
              header="Pickup Location"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            {/* <Column
              field="destinationLocation"
              header="Destination Location"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column> */}
            <Column
              field="totalServiceCost"
              header="Service Cost($)"
              sortable
              body={(values) =>
                values?.totalServiceCost
                  ? formatMoney(values?.totalServiceCost, "$", 2)
                  : "N/A"
              }
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="totalWeight"
              header="Total Weight(Lbs)"
              sortable
              body={(values) =>
                formatMoney(Number(values.totalWeight), "", 0) + "lbs"
              }
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="status"
              header="Status"
              sortable
              body={(rowData: any) => {
                let color = "#388e3c";

                if (rowData.status === "SUBMITTED") {
                  color = "#f7773c";
                }

                if (rowData.status === "PENDING_APPROVAL") {
                  color = "#d65c49";
                }

                return (
                  <span
                    style={{
                      padding: "0.5rem",
                      backgroundColor: color,
                      fontSize: "0.85rem",
                      color: "#fff",
                      borderRadius: "5px",
                    }}
                  >
                    {rowData.status}
                  </span>
                );
              }}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
          </DataTable>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paginator
              first={first}
              rows={rowsPerPage}
              totalRecords={totalServiceRequests}
              rowsPerPageOptions={[5, 10, 15, 20]}
              onPageChange={onPageChage}
            ></Paginator>

            <Typography>Total Records: {totalServiceRequests}</Typography>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
