import React, { useEffect, useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
// import Timeline from "@material-ui/lab/Timeline";
// import TimelineItem from "@material-ui/lab/TimelineItem";
// import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
// import TimelineConnector from "@material-ui/lab/TimelineConnector";
// import TimelineContent from "@material-ui/lab/TimelineContent";
// import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
// import TimelineDot from "@material-ui/lab/TimelineDot";
import {
  CollectionsBookmarkOutlined as ServiceRequestIcon,
  AssignmentTurnedInOutlined as OrderIcon,
  LocalShippingOutlined as DriverIcon,
  ScheduleOutlined as ScheduleIcon,
  HowToRegOutlined as SignedIcon,
  CloudUploadOutlined as UploadIcon,
  AssignmentTurnedIn as CompletedIcon,
  Clear,
  Cached
} from "@material-ui/icons";
import { Typography, Paper, IconButton, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { changeSelectedOrder } from "../../../redux/orders/actions";
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import { getOrderTimeline } from "../../../services/orders.service";
import { Link } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import './Timeline.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    marginBottom: "2rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  secondaryTail: {
    backgroundColor: theme.palette.primary.main,
  },
  successColor: {
    backgroundColor: theme.palette.success.main,
  },
  secondaryTailGrey: {
    backgroundColor: theme.palette.grey[400],
  },
  timelineItem: {},
  timelineDate: {
    marginTop: "10px",
  },
  subHeader: {
    fontWeight: "bold",
    marginRight: "10px",
  },
  row: { display: "flex", flexDirection: "row" },
  refreshbtn: {
    borderRadius: "5px",
    color: "white",
    background: "#d65c49",
    marginRight: "1rem",
    padding: "5px",
    "&:hover": {
      backgroundColor: alpha("#d65c49", 0.75),
    },
  },
}));

export default function TimelineComponent(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timeLineData, setTimeLineData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshBtnDisabled, setRefreshBtnDisabled] = useState<boolean>(false);

  useEffect(() => {
    (async function fetchOrderDetails() {
      setLoading(true);
      setRefreshBtnDisabled(true);
      const orderDetailsResponse = await getOrderTimeline(props.orderDetails.orderId);
      if (orderDetailsResponse.status === 200) {
        const orderDetails = orderDetailsResponse.data;
        setTimeLineData(constructTimeLine(orderDetails));
      }
      setLoading(false);
      setRefreshBtnDisabled(false);
    })();
  }, [])

  const refresh = () => {
    (async function fetchOrderDetails() {
      setLoading(true);
      setRefreshBtnDisabled(true);
      const orderDetailsResponse = await getOrderTimeline(props.orderDetails.orderId);
      if (orderDetailsResponse.status === 200) {
        const orderDetails = orderDetailsResponse.data;
        setTimeLineData(constructTimeLine(orderDetails));
      }
      setLoading(false);
      setRefreshBtnDisabled(false);
    })();
  }

  /**
   * Contruct the timeline from the api
   * @param data 
   */
  const constructTimeLine = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      color: ["#9C27B0","#9b1de2","#d65c49","#388e3c","#1769aa"],
      icon: setIcons(item._id)
    }));
  }

  /**
   * Sets appropriate icons
   * @param _id 
   * @returns 
   */
  const setIcons = (_id: string) => {
    switch(_id) {
      case 'SERVICE_REQUEST': return (<ServiceRequestIcon style={{ fontSize: "2rem" }}/>);
      case 'WORK_ORDER': return (<OrderIcon style={{ fontSize: "2rem" }} />);
      case 'DRIVERS_ASSIGNED': return ( <DriverIcon style={{ fontSize: "2rem" }}/>)
      case 'SCHEDULE': return ( <ScheduleIcon style={{ fontSize: "2rem" }}/>)
      case 'WEIGHT_AND_PALET_INFO': return ( <SignedIcon style={{ fontSize: "2rem" }}/>)
      case 'DOCUMENTS': return ( <UploadIcon style={{ fontSize: "2rem" }}/>)
      case 'INVOICE': return ( <CompletedIcon style={{ fontSize: "2rem" }}/>)
    }
  }

  const customizedMarker = (item:any) => {
    return (
      <span 
        style={{
          display: 'flex',
          width: '3rem',
          height: '3rem',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#ffffff',
          borderRadius: '50%',
          backgroundColor: item.color[Math.floor(Math.random()*item.color.length)]
        }}>
        {item.icon}
      </span>
    );
  };

  const customizedContent = (item: any) => {
      return (
          <Card>
            {
              item.content.map((row: any, index: number) => (
                <div className={classes.row} key={index}>
                    <Typography className={classes.subHeader}>
                      {row.title || 'N/A'}
                    </Typography>
                    <Typography>
                      {
                        row.title === 'Order ID:' ? 
                        <Link to={`/order-summary/${row.value}`} >{row.value}</Link> : row.value || 'N/A'
                      }
                    </Typography>
                </div>
              ))
            }
          </Card>
      );
  };

  return (
    <Dialog
      visible={props.modalVisible}
      onHide={() => {
        props.handleClose({ show: false, refreshPage: false })
      }}
      modal
      draggable={false}
      style={{
        width: "75%",
      }}
      contentStyle={{
        padding: '40px',
        borderRadius: "5px",
      }}
      showHeader={false}
    >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "end",
        justifyContent: "flex-end",
        marginBottom:'15px',
      }}
    >
      {
        !refreshBtnDisabled && (
          <Tooltip title="Refresh Data">
              <IconButton
                color="primary"
                aria-label="refresh"
                size="small"
                onClick={refresh}
                className={classes.refreshbtn}
              >
                <Cached />
              </IconButton>
          </Tooltip>
        )
      }
      <IconButton
        onClick={() => {
          props.handleClose({ show: false, refreshPage: false });
        }}
        style={{
          position: "absolute",
          right: "-1rem",
          top: "-1rem",
          backgroundColor: "#1769aa",
          color: "#fff",
        }}
      >
        <Clear color="inherit" />
      </IconButton>
    </div>
    <div className='customised-timeline-card'>
      {
        loading &&  
        (<div style={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <ProgressSpinner />
        </div>)
      }
      {
        !loading &&
        <Timeline 
          value={timeLineData}
          align="alternate"
          className="customized-timeline"
          marker={customizedMarker}
          opposite={(item) => item.status} 
          content={customizedContent}
        />
      }
    </div>
     {/* <Timeline align="alternate">
      <TimelineItem className={classes.timelineItem}>
        <TimelineOppositeContent>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.timelineDate}
          >
            07/20/2021 9:30 am
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" className={classes.successColor}>
            <ServiceRequestIcon />
          </TimelineDot>
          <TimelineConnector className={classes.successColor} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.row}>
              <Typography className={classes.subHeader}>
                Service Request:
              </Typography>
              <Typography>SR00002</Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.subHeader}>
                Requested By:
              </Typography>
              <Typography>Eric</Typography>
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem className={classes.timelineItem}>
        <TimelineOppositeContent>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.timelineDate}
          >
            07/20/2021 10:00 am
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" className={classes.successColor}>
            <OrderIcon />
          </TimelineDot>
          <TimelineConnector
            className={
              orderSummary?.status === "Completed"
                ? classes.successColor
                : classes.secondaryTailGrey
            }
          />
        </TimelineSeparator>
        <TimelineContent>
          <Paper
            elevation={3}
            className={classes.paper}
            onClick={_handleOrderNumberClick}
          >
            <div className={classes.row}>
              <Typography className={classes.subHeader}>Work Order:</Typography>
              <Link>{orderNumber}</Link>
            </div>

            <div className={classes.row}>
              <Typography className={classes.subHeader}>
                Submitted By:
              </Typography>
              <Typography>China De Leone</Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.subHeader}>
                Approved By:
              </Typography>
              <Typography>Eric</Typography>
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem className={classes.timelineItem}>
        {orderSummary?.status === "Completed" && (
          <TimelineOppositeContent>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.timelineDate}
            >
              07/20/2021 10:00 am
            </Typography>
          </TimelineOppositeContent>
        )}
        <TimelineSeparator>
          <TimelineDot
            color={orderSummary?.status === "Completed" ? "primary" : "grey"}
            className={classes.successColor}
          >
            <DriverIcon />
          </TimelineDot>
          <TimelineConnector className={classes.successColor} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.row}>
              <Typography className={classes.subHeader}>Driver:</Typography>
              <Typography>Will B</Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.subHeader}>Team:</Typography>
              <Typography>Mark, Ben</Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.subHeader}>Truck:</Typography>
              <Typography>ABC 1234</Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.subHeader}>Fuel Miles:</Typography>
              <Typography>80</Typography>
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem className={classes.timelineItem}>
        {orderSummary?.status === "Completed" && (
          <TimelineOppositeContent>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.timelineDate}
            >
              07/20/2021 10:00 am
            </Typography>
          </TimelineOppositeContent>
        )}
        <TimelineSeparator>
          <TimelineDot
            color={orderSummary?.status === "Completed" ? "primary" : "grey"}
            className={classes.successColor}
          >
            <ScheduleIcon />
          </TimelineDot>
          {orderSummary?.status === "Completed" && (
            <TimelineConnector className={classes.successColor} />
          )}
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.row}>
              <Typography className={classes.subHeader}>Schedule:</Typography>
              <Typography>07/27/2021 8:00AM-12:00PM</Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.subHeader}>Arrival:</Typography>
              <Typography>8:00 AM</Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.subHeader}>Departure:</Typography>
              <Typography>3:00 PM</Typography>
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      {orderSummary?.status === "Completed" && (
        <>
          <TimelineItem className={classes.timelineItem}>
            <TimelineOppositeContent>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.timelineDate}
              >
                07/21/2021 8:00 am
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" className={classes.successColor}>
                <SignedIcon />
              </TimelineDot>
              {orderSummary?.status === "Completed" && (
                <TimelineConnector className={classes.successColor} />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <div className={classes.row}>
                  <Typography className={classes.subHeader}>
                    Total Pallets:
                  </Typography>
                  <Typography>1</Typography>
                </div>

                <div className={classes.row}>
                  <Typography className={classes.subHeader}>
                    Total Weight:
                  </Typography>
                  <Typography>939 Lbs.</Typography>
                </div>

                <div className={classes.row}>
                  <Typography className={classes.subHeader}>
                    Customer sign off:
                  </Typography>
                  <Typography>Wallace Nashid</Typography>
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineOppositeContent>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.timelineDate}
              >
                07/21/2021 8:00 am
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" className={classes.successColor}>
                <UploadIcon />
              </TimelineDot>
              {orderSummary?.status === "Completed" && (
                <TimelineConnector className={classes.successColor} />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <div className={classes.row}>
                  <Typography className={classes.subHeader}>
                    Document upload:
                  </Typography>
                  <Typography>2</Typography>
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timelineItem}>
            <TimelineOppositeContent>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.timelineDate}
              >
                07/22/2021 10:15 am
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" className={classes.successColor}>
                <CompletedIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <div className={classes.row}>
                  <Typography className={classes.subHeader}>
                    Asset Recovery Waybill:
                  </Typography>
                  <Typography>16789</Typography>
                </div>

                <div className={classes.row}>
                  <Typography className={classes.subHeader}>
                    Invoice number:
                  </Typography>
                  <Typography>10746</Typography>
                </div>

                <div className={classes.row}>
                  <Typography className={classes.subHeader}>Type:</Typography>
                  <Typography>collect</Typography>
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </>
      )}
    </Timeline> */}
  </Dialog>
  );
}
