import * as ordersTypes from "./types";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getOrderData } from "../../services/orders.service";

const types = {
  ...ordersTypes,
};

export const changeSelectedOrder = (orderNumber: String) => ({
  type: types.CHANGE_SELECTED_ORDER,
  payload: { orderNumber },
});

/**
 * Action to dispatch customer orders
 * @param orderNumber 
 * @returns 
 */
export const getAllOrders = (searchText: string = '', pageNumber: number = 1,pageSize: number = 10,filters: string = ''): ThunkAction<void, any, unknown, AnyAction> =>
async (dispatch): Promise<void> => {
  const orderResponse = await getOrderData(searchText,pageNumber,pageSize, filters);
  if (orderResponse.status === 200) {
    dispatch({ type: types.GET_ORDER_SUCCESS, payload: orderResponse.data });
  } else {
    dispatch({ type: types.GET_ORDER_ERROR });
  }
};

