import { useState } from "react";
import { useDispatch } from "react-redux";

import CreateServiceRequest from "./CreateServiceRequest";
import { createServiceRequest } from "../../../services/serviceRequests.service";
import { showAlert } from "../../../redux/alerts/actions";
import {
  uploadFileToFirebase,
  uploadFilesToDatabase,
} from "../../../services/documents.service";

export default function CreateServiceRequestWrapper(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const _handleSubmit = async (data: any) => {
    const payload = {
      pickupDetails: {
        ...data.pickupDetails,
        generalInstructions: data.instructions.generalInstructions,
        priorToPickupInstructions: data.instructions.priorToPickupInstructions,
        onSiteInstructions: data.instructions.onSiteInstructions,
        afterPickupInstructions: data.instructions.afterPickupInstructions,
      },
      destinationDetails: {
        ...data.destinationDetails,
      },
      shipmentDetails: {
        ...data.shipmentDetails,
      },
    };

    setIsLoading(true);

    const serviceRequestResponse = await createServiceRequest(payload);
    if (serviceRequestResponse.status === 200) {
      // upload documents to firebase
      if (data.instructions.documents) {
        let promises: any = [];

        data.instructions.documents.forEach((file: any) => {
          promises.push(
            uploadFileToFirebase(serviceRequestResponse?.data?.requestId, file)
          );
        });

        const urls = await Promise.all(promises);

        const documents = urls.map((res: any) => ({
          documentUrl: res.data.url,
          documentName: res.data.name,
          documentType: "INSTRUCTIONS",
        }));

        await uploadFilesToDatabase({
          serviceRequestId: serviceRequestResponse?.data?.id,
          documents,
        });
      }

      dispatch(
        showAlert({
          message: "Service request added successfully",
          type: "success",
        })
      );
      props.history.push("/service-request");
    }
    setIsLoading(false);
  };

  return (
    <CreateServiceRequest
      currentStep={0}
      completedSteps={-1}
      onSubmitServiceRequest={_handleSubmit}
      isSubmitting={isLoading}
      flowName="Create"
      {...props}
    />
  );
}
