import { useState } from "react";
import {
  Grid,
  Paper,
  InputBase,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Search as SearchIcon } from "@material-ui/icons";

import Map from "../../assets/images/Map.png";
import GoogleMapComponent from "./GoogleMapComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    map: {},
    select: {
      width: "300px",
      marginRight: "40px",
      marginBottom: "10px",
    },
    search: {
      position: "relative",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: "600px",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px",
    },
  })
);

export default function Tracker({ history }: any) {
  const initialValues = history?.location?.state;

  // const [asset, setAsset] = useState(initialValues?.asset);
  const [searchValue, setSearchValue] = useState(initialValues?.searchValue);
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={12}>
        <Paper className={classes.paper}>
          <div className={classes.row}>
            {/* <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search For Orders..."
                classes={{
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchValue}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div> */}
          </div>

          <GoogleMapComponent />
        </Paper>
      </Grid>
    </Grid>
  );
}
