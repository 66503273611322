export const formatServiceRequestDataForForm = (data: any) => {
  return {
    pickupDetails: {
      primaryContactFirstName: "",
      primaryContactLastName: "",
      primaryContactPhoneNumber: "",
      secondaryContactFirstName: "",
      secondaryContactLastName: "",
      secondaryContactPhoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      ...data.pickupDeatils,
    },
    destinationDetails: {
      primaryContactFirstName: "",
      primaryContactLastName: "",
      primaryContactPhoneNumber: "",
      secondaryContactFirstName: "",
      secondaryContactLastName: "",
      secondaryContactPhoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      ...data.destinationDetails,
    },
    instructions: {
      generalInstructions: "",
      priorToPickupInstructions: "",
      onSiteInstructions: "",
      afterPickupInstructions: "",
      ...data.instructions,
    },
    shipmentDetails: {
      totalWeight: "",
      totalPalletsRequired: "",
      itemsToDeliver: "",
      laborsNeeded: "",
      laborTime: "",
      noOfVehiclesNeeded: "",
      vehicleType: "",
      customOrderId: "",
    },
    confirmation: false,
  };
};
