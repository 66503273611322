import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getUserProfile } from "../../services/dashboard.service";
import * as profileType from "./types";

const types = {
  ...profileType,
};

/**
 * Action to fetch user profile
 * @param payload 
 * @returns 
 */
export const fetchProfileAction =
  (): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    const getProfileResponse = await getUserProfile();
    if (getProfileResponse.status === 200) {
      dispatch({ type: types.GET_PROFILE_SUCCESS, payload: getProfileResponse.data });
    } else {
      dispatch({ type: types.GET_PROFILE_ERROR });
    }
  };

