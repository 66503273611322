import { combineReducers } from "@reduxjs/toolkit";

import usersReducer from "./users/reducer";
import userReducer from "./user/reducer";
import ordersReducer from "./orders/reducer";
import documentsReducer from "./documents/reducer";
import serviceRequestReducer from "./serviceRequests/reducer";
import alertReducer from "./alerts/reducer";
import dashboardReducer from "./dashboard/reducer";
import profileReducer from "./profile/reducer";

const rootReducer = combineReducers({
  dashboard:dashboardReducer,
  profile:profileReducer,
  users: usersReducer,
  user: userReducer,
  orders: ordersReducer,
  documents: documentsReducer,
  serviceRequests: serviceRequestReducer,
  alerts: alertReducer
});

export default rootReducer;
