import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, IconButton, Paper } from "@material-ui/core";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import SendIcon from "@material-ui/icons/Send";
import RoomIcon from "@material-ui/icons/Room";
import PermPhoneMsgTwoToneIcon from "@material-ui/icons/PermPhoneMsgTwoTone";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import { TabView, TabPanel } from "primereact/tabview";

import { showAlert } from "../../redux/alerts/actions";
import { createNewMessageGroup } from "../../services/message.service";
import MessageViewer from "./MessageViewer";

export default function ContactUs({ location }: any) {
  const dispatch = useDispatch();

  const profile = useSelector((state: any) => state?.profile);

  const formik = useFormik({
    initialValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      emailAddress: profile?.emailAddress,
      subject: "",
      message: "",
    },
    validate: (data: {
      firstName: string;
      lastName: string;
      emailAddress: string;
      subject: string;
      message: string;
    }) => {
      let errors: {
        firstName?: string;
        lastName?: string;
        emailAddress?: string;
        subject?: string;
        message?: string;
      } = {};

      if (!data.subject) {
        errors.subject = "Email Address is required.";
      }

      if (!data.message) {
        errors.message = "Password is required.";
      }

      return errors;
    },
    onSubmit: (data: any) => {
      (async () => {
        const sendMessageResponse = await createNewMessageGroup({
          subject: data.subject,
          message: data.message,
        });
        if (sendMessageResponse.status === 200) {
          dispatch(
            showAlert({
              message: "Message Sent Successfully!",
              type: "success",
            })
          );
        } else {
          dispatch(
            showAlert({ message: "Oops! something went wrong.", type: "error" })
          );
        }
      })();
      formik.resetForm();
    },
  });

  const isFormFieldValid = (
    name: "subject" | "message" | "firstName" | "lastName" | "emailAddress"
  ) => !!(formik.touched[name] && formik.errors[name]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      <TabView activeIndex={location?.state?.messageGroupId ? 1 : 0}>
        <TabPanel header="Send Us A Message">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                height: "auto",
                padding: "2rem 2rem 2rem 2rem",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                }}
                onSubmit={formik.handleSubmit}
              >
                <Typography variant="h5" style={{ marginBottom: "2rem" }}>
                  Send Us a Message
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    className="p-field"
                    style={{
                      width: "100%",
                      marginRight: "1rem",
                    }}
                  >
                    <span className="p-float-label">
                      <InputText
                        id="firstName"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("firstName"),
                        })}
                        style={{ width: "100%", color: "black", opacity: 1 }}
                        disabled
                      />
                      <label
                        htmlFor="firstName"
                        className={classNames({
                          "p-error": isFormFieldValid("firstName"),
                        })}
                      >
                        First Name
                      </label>
                    </span>
                  </div>

                  <div
                    className="p-field"
                    style={{ width: "100%", marginLeft: "1rem" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        id="lastName"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("lastName"),
                        })}
                        style={{ width: "100%", color: "black", opacity: 1 }}
                        disabled
                      />
                      <label
                        htmlFor="lastName"
                        className={classNames({
                          "p-error": isFormFieldValid("lastName"),
                        })}
                      >
                        Last Name
                      </label>
                    </span>
                  </div>
                </div>

                <div className="p-field" style={{ width: "100%" }}>
                  <span className="p-float-label">
                    <InputText
                      id="emailAddress"
                      name="emailAddress"
                      value={formik.values.emailAddress}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("emailAddress"),
                      })}
                      style={{ width: "100%", color: "black", opacity: 1 }}
                      disabled
                    />
                    <label
                      htmlFor="emailAddress"
                      className={classNames({
                        "p-error": isFormFieldValid("emailAddress"),
                      })}
                    >
                      Email Address
                    </label>
                  </span>
                </div>
                <div className="p-field" style={{ width: "100%" }}>
                  <span className="p-float-label">
                    <InputText
                      id="subject"
                      name="subject"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("subject"),
                      })}
                      style={{ width: "100%", color: "black", opacity: 1 }}
                    />
                    <label
                      htmlFor="subject"
                      className={classNames({
                        "p-error": isFormFieldValid("subject"),
                      })}
                    >
                      Subject *
                    </label>
                  </span>
                </div>

                <div className="p-field" style={{ width: "100%" }}>
                  <span className="p-float-label">
                    <InputTextarea
                      id="message"
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("message"),
                      })}
                      autoResize
                      style={{
                        width: "100%",
                        maxHeight: "10rem",
                        overflowX: "hidden",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        color: "black",
                        opacity: 1,
                      }}
                    />
                    <label
                      htmlFor="message"
                      className={classNames({
                        "p-error": isFormFieldValid("message"),
                      })}
                    >
                      Message *
                    </label>
                  </span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    type="submit"
                    color="primary"
                    style={{
                      backgroundColor: "#1769aa",
                      color: "#fff",
                      borderRadius: 0,
                      alignSelf: "flex-end",
                      padding: "0.5rem 1rem",
                      borderTopRightRadius: "25px",
                      borderBottomLeftRadius: "25px",
                    }}
                  >
                    <Typography>Send</Typography>
                    <SendIcon style={{ marginLeft: "1rem" }} />
                  </IconButton>
                </div>
              </form>
            </Paper>

            <Paper
              style={{
                position: "absolute",
                right: "1rem",
                top: "1rem",
                display: "flex",
                flexDirection: "column",
                width: "45%",
                height: "75vh",
                borderRadius: "5px",
                backgroundColor: "#1769aa",
                // backgroundImage: `url(${ContactUsImage})`,
                // backgroundSize: "cover",
                // backgroundRepeat: "no-repeat",
                color: "#fff",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  padding: "2rem 2rem 2rem 2rem",
                  borderRadius: "5px",
                }}
              >
                <Typography variant="h5">Contact Information</Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "1rem 0",
                  }}
                >
                  <div
                    style={{
                      padding: "0.85rem 0.85rem 0.5rem 0.85rem",
                      margin: "1rem",
                      backgroundColor: "#d65c49",
                      borderRadius: "50%",
                    }}
                  >
                    <RoomIcon />
                  </div>

                  <div>
                    <Typography variant="subtitle2">Location:</Typography>
                    <Typography variant="h6">
                      3850 Dulles S Ct, Chantilly, VA 20151
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "1rem 0",
                  }}
                >
                  <div
                    style={{
                      padding: "0.85rem 0.85rem 0.5rem 0.85rem",
                      margin: "1rem",
                      backgroundColor: "#d65c49",
                      borderRadius: "50%",
                    }}
                  >
                    <PermPhoneMsgTwoToneIcon />
                  </div>

                  <div>
                    <Typography variant="subtitle2">Phone Number:</Typography>
                    <Typography variant="h6">+1 (434) 322-3517</Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "1rem 0",
                  }}
                >
                  <div
                    style={{
                      padding: "0.85rem 0.85rem 0.5rem 0.85rem",
                      margin: "1rem",
                      backgroundColor: "#d65c49",
                      borderRadius: "50%",
                    }}
                  >
                    <MailOutlineTwoToneIcon />
                  </div>

                  <div>
                    <Typography variant="subtitle2">Email Address:</Typography>
                    <Typography variant="h6">
                      support@caegissolutions.com
                    </Typography>
                  </div>
                </div>

                {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "1rem 0",
            }}
          >
            <IconButton
              style={{
                margin: "1rem",
                backgroundColor: "#fff",
              }}
            >
              <i
                className="pi pi-facebook"
                style={{ fontSize: "2rem", color: "#4267B2" }}
              />
            </IconButton>

            <IconButton
              style={{
                margin: "1rem",
                backgroundColor: "#fff",
              }}
            >
              <InstagramIcon
                style={{
                  fontSize: "2rem",
                  color: "#fff",
                  background:
                    "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
                  borderRadius: "5px",
                }}
              />
            </IconButton>

            <IconButton
              style={{
                margin: "1rem",
                backgroundColor: "#fff",
              }}
            >
              <i
                className="pi pi-twitter"
                style={{ fontSize: "2rem", color: "#1DA1F2" }}
              />
            </IconButton>

            <IconButton
              style={{
                margin: "1rem",
                backgroundColor: "#fff",
              }}
            >
              <LinkedInIcon style={{ fontSize: "2rem", color: "#1DA1F2" }} />
            </IconButton>

            <IconButton
              style={{
                margin: "1rem",
                backgroundColor: "#fff",
              }}
            >
              <i
                className="pi pi-youtube"
                style={{ fontSize: "2rem", color: "#FF0000" }}
              />
            </IconButton>
          </div> */}
              </div>
            </Paper>
          </div>
        </TabPanel>
        <TabPanel header="Message History">
          <MessageViewer location={location} />
        </TabPanel>
      </TabView>
    </div>
  );
}
