import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to fetch dashboard items
 * @returns
 */
export const getDashboardData = async (searchText: string, pageNumber: number,pageSize: number, filters: string) => {
    try {
        return await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/dashboard/get-customer?page_number=${pageNumber}&page_size=${pageSize}&search_term=${searchText ||''}&filters=${filters || ''}`);
    } catch (error: any) {
        return {
          status: 500,
          data: null,
          statusText: 'Oops! something went wrong'
        }
    }
};
/**
 * This function is used to fetch user profile
 * @returns 
 */
export const getUserProfile = async () => {
    try {
        return await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile/get-user-profile`);
    } catch (error: any) {
        return {
          status: 500,
          data: null,
          statusText: 'Oops! something went wrong'
        }
    }
};
/**
 * This function is used to update user profile
 * @returns 
 */
 export const updateUserProfile = async (payload: any) => {
    try {
        return await Axios.post(`${process.env.REACT_APP_API_BASE_URL}/profile/update-user-profile`, payload);
    } catch (error: any) {
        return {
          status: 500,
          data: null,
          statusText: 'Oops! something went wrong'
        }
    }
};