import { BrowserRouter as Router, Switch } from "react-router-dom";

import LoginScreen from "./Login";
import ServiceRequests from "./ServiceRequests";
import OrderHistory from "./Orders/History";
import InProgress from "./Orders/InProgress";
import PendingApproval from "./Orders/PendingApproval";
import OrderSummary from "./Orders/OrderSummary";
// import Documents from "./Documents";
import CreateOrder from "./ServiceRequests/CreateServiceRequest";
import ProtectedRoute from "../components/ProtectedRoute";
import ViewServiceRequest from "./ServiceRequests/ViewServiceRequest";
import EditServiceRequest from "./ServiceRequests/EditServiceRequest";
import Help from "./Help";
import ContactUs from "./ContactUs";
import Tracker from "./Tracker";
// import SignupScreen from "./Signup";
import UnprotectedRoute from "../components/UnprotectedRoute";
import UnderConstruction from "../components/UnderConstruction";
import UserProfile from "./Profile";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <UnprotectedRoute path="/" exact component={LoginScreen} />
        {/* <Route path="/signup" component={SignupScreen} /> */}
        <ProtectedRoute path="/service-request" component={ServiceRequests} />
        <ProtectedRoute path="/order-history" component={OrderHistory} />
        <ProtectedRoute path="/order-in-progress" component={InProgress} />
        <ProtectedRoute path="/pending-approval" component={PendingApproval} />
        <ProtectedRoute exact path="/order-summary/:id" component={OrderSummary} />
        <ProtectedRoute path="/documents" component={UnderConstruction} />
        <ProtectedRoute path="/create-new-order" component={CreateOrder} />
        <ProtectedRoute path="/user-profile" component={UserProfile} />
        <ProtectedRoute
          path="/edit-service-request/:id"
          component={EditServiceRequest}
        />
        <ProtectedRoute
          path="/view-service-request/:id"
          component={ViewServiceRequest}
        />
        <ProtectedRoute path="/order-tracker" component={Tracker} />
        <ProtectedRoute path="/help-center" component={Help} />
        <ProtectedRoute path="/contact-us" component={ContactUs} />
      </Switch>
    </Router>
  );
}
