import * as ordersTypes from "./types";

const types = {
  ...ordersTypes,
};

export const changeSelectedServiceRequest = (orderNumber: any) => ({
  type: types.CHANGE_SELECTED_SERVICE_REQUEST,
  payload: { orderNumber },
});
