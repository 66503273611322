import React from "react";
import { useDispatch } from "react-redux";
import { Typography, IconButton } from "@material-ui/core";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import SendIcon from "@material-ui/icons/Send";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";

import { showAlert } from "../../redux/alerts/actions";
import { createNewMessageGroup } from "../../services/message.service";

export default function MessagePanel({ userProfile }: any) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    validate: (data: { subject: string; message: string }) => {
      let errors: {
        subject?: string;
        message?: string;
      } = {};

      if (!data.subject) {
        errors.subject = "Email Address is required.";
      }

      if (!data.message) {
        errors.message = "Password is required.";
      }

      return errors;
    },
    onSubmit: (data: { subject: string; message: string }) => {
      (async () => {
        const sendMessageResponse = await createNewMessageGroup(data);
        if (sendMessageResponse.status === 200) {
          dispatch(
            showAlert({
              message: "Message Sent Successfully!",
              type: "success",
            })
          );
        } else {
          dispatch(
            showAlert({ message: "Oops! something went wrong.", type: "error" })
          );
        }
      })();
      formik.resetForm();
    },
  });

  const isFormFieldValid = (name: "subject" | "message") =>
    !!(formik.touched[name] && formik.errors[name]);
  //   const getFormErrorMessage = (name: "subject" | "message") => {
  //     return (
  //       isFormFieldValid(name) && (
  //         <small className="p-error" style={{ fontSize: "0.65rem" }}>
  //           {formik.errors[name]}
  //         </small>
  //       )
  //     );
  //   };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1769aa",
          padding: "1rem 1rem 8rem 1rem",
          color: "#fff",
        }}
      >
        <Typography variant="h5">
          Hello, {userProfile?.firstName} {userProfile?.lastName}
        </Typography>

        <Typography>What can we assist you with?</Typography>
      </div>

      <form
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f8f9fa",
          padding: "2rem 1rem 1rem 1rem",
          color: "#fff",
          position: "absolute",
          zIndex: 1,
          left: "1.5rem",
          right: "1.5rem",
          top: "6rem",
          borderRadius: "5px",
          justifyContent: "center",
          alignItems: "center",
        }}
        onSubmit={formik.handleSubmit}
      >
        <div className="p-field" style={{ width: "100%", fontSize: "0.85rem" }}>
          <span className="p-float-label">
            <InputText
              id="subject"
              name="subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("subject"),
              })}
              style={{ width: "100%", fontSize: "0.85rem" }}
            />
            <label
              htmlFor="subject"
              className={classNames({
                "p-error": isFormFieldValid("subject"),
              })}
            >
              Subject *
            </label>
          </span>
        </div>

        <div className="p-field" style={{ width: "100%", fontSize: "0.85rem" }}>
          <span className="p-float-label">
            <InputTextarea
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("message"),
              })}
              autoResize
              style={{
                width: "100%",
                fontSize: "0.85rem",
                maxHeight: "8rem",
                overflowX: "hidden",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
            />
            <label
              htmlFor="message"
              className={classNames({
                "p-error": isFormFieldValid("message"),
              })}
            >
              Message *
            </label>
          </span>
        </div>

        <IconButton
          type="submit"
          style={{
            backgroundColor: "#1769aa",
            color: "#fff",
            borderRadius: 0,
            alignSelf: "flex-end",
            padding: "0.5rem 1rem",
            borderTopRightRadius: "25px",
            borderBottomLeftRadius: "25px",
          }}
        >
          <Typography>Send</Typography>
          <SendIcon style={{ marginLeft: "1rem" }} />
        </IconButton>
      </form>

      <div
        style={{
          position: "absolute",
          bottom: "1rem",
          right: "1rem",
          left: "1rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <OfflineBoltIcon color="primary" style={{ marginRight: "0.5rem" }} />
        <Typography>By Entuber</Typography>
      </div>
    </>
  );
}
