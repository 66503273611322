import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getDashboardData } from "../../services/dashboard.service";
import * as dashboardTypes from "./types";
import * as serviceRequestTypes from "../serviceRequests/types";

const types = {
  ...dashboardTypes,
  ...serviceRequestTypes
};


/**
 * action to fetch dashboard data
 * @returns 
 */
export const fetchDashboardData =
  (searchText: string = '',pageNumber:number =1,pageSize: number = 10,filters: string = ''): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    const dashboardResponse = await getDashboardData(searchText,pageNumber,pageSize, filters);
    if (dashboardResponse.status === 200) {
      dispatch({ type: types.SHOW_DASHBOARD_SUCCESS, payload: dashboardResponse.data });
      dispatch({ type: types.FETCH_SERVICE_REQUEST_SUCCESS, payload: dashboardResponse.data.serviceRequests });
    } else {
      dispatch({ type: types.SHOW_DASHBOARD_ERROR });
      dispatch({ type: types.FETCH_SERVICE_REQUEST_FAILED });
    }
  };
