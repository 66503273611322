import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to download a single file to firebase
 * @returns
 */
export const downloadFileFromFirebase = async (filePath: string) => {
  try {
    const storage = getStorage();
    const url = await getDownloadURL(ref(storage, filePath));

    return await Axios.get(url);
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

export const downloadFileFromFirebaseToLocal = async (
  filePath: string,
  fileName: string
) => {
  try {
    const storage = getStorage();
    const documentUrl = await getDownloadURL(ref(storage, filePath));

    const res = await Axios({
      method: "get",
      url: documentUrl,
      responseType: "blob",
    });

    console.log(res);

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

    return {
      status: 200,
      data: res.data,
    };
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

export const previewFileFromFirebase = async (
  filePath: string,
  fileName: string
) => {
  try {
    const storage = getStorage();
    const documentUrl = await getDownloadURL(ref(storage, filePath));

    const res = await Axios({
      method: "get",
      url: documentUrl,
      responseType: "blob",
    });

    var fileURL = window.URL.createObjectURL(res.data);
    let tab: any = window.open("_blank");
    tab.location.href = fileURL;

    return {
      status: 200,
      data: res.data,
    };
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

/**
 * This function is used to upload a single file to firebase
 * @returns
 */
export const uploadFileToFirebase = async (
  serviceRequestId: string,
  file: any
) => {
  const storage = getStorage();
  const path = `entuber-caegis-customer/${serviceRequestId}/` + file.name;
  const storageRef = ref(storage, path);

  try {
    await uploadBytes(storageRef, file);

    return { status: 200, data: { name: file.name, url: path } };
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

/**
 * This function is used to upload files to database
 * @returns
 */
export const uploadFilesToDatabase = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/documents/upload`,
      payload
    );
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

/**
 * This function is used to delete files in firebase storage and database
 * @returns
 */
export const deleteFileFromFirebaseAndDatabase = async (
  filePath: string,
  serviceRequestId: number,
  documentId: number
) => {
  try {
    const storage = getStorage();

    // Create a reference to the file to delete
    const desertRef = ref(storage, filePath);

    await deleteObject(desertRef);

    return await Axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/documents/delete?serviceRequestId=${serviceRequestId}&documentId=${documentId}`
    );
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
