import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

import CreateServiceRequest from "../CreateServiceRequest/CreateServiceRequest";
import {
  approveQuotation,
  editServiceRequest,
  fetchServiceRequest,
} from "../../../services/serviceRequests.service";
import { extractServiceRequestDataFromAPI } from "../ViewServiceRequest/extractServiceRequestDataFromAPI";
import { showAlert } from "../../../redux/alerts/actions";
import {
  uploadFileToFirebase,
  uploadFilesToDatabase,
} from "../../../services/documents.service";

export default function EditServiceRequest(props: any) {
  const [serviceRequestOrigData, setServiceRequestOrigData] =
    useState<any>(null);
  const [serviceRequestData, setServiceRequestData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const params = useParams<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const serviceDetailsResponse = await fetchServiceRequest(params.id);
      if (serviceDetailsResponse.status === 200) {
        const formattedData: any = extractServiceRequestDataFromAPI(
          serviceDetailsResponse.data
        );

        setServiceRequestData(formattedData);
        setServiceRequestOrigData(serviceDetailsResponse.data);
      }
      setShowLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSubmit = async (data: any) => {
    const payload = {
      pickupDetails: {
        ...data.pickupDetails,
        generalInstructions: data.instructions.generalInstructions,
        priorToPickupInstructions: data.instructions.priorToPickupInstructions,
        onSiteInstructions: data.instructions.onSiteInstructions,
        afterPickupInstructions: data.instructions.afterPickupInstructions,
      },
      destinationDetails: {
        ...data.destinationDetails,
      },
      shipmentDetails: {
        ...data.shipmentDetails,
      },
    };

    setIsLoading(true);

    // upload documents to firebase
    if (
      data?.instructions?.documents?.length &&
      data?.instructions?.documents?.filter((x: any) => !x.id && x.name)?.length
    ) {
      let promises: any = [];

      data?.instructions?.documents
        ?.filter((x: any) => !x.id && x.name)
        .forEach((file: any) => {
          promises.push(
            uploadFileToFirebase(serviceRequestOrigData?.requestId, file)
          );
        });

      const urls = await Promise.all(promises);

      const documents = urls.map((res: any) => ({
        documentUrl: res.data.url,
        documentName: res.data.name,
        documentType: "INSTRUCTIONS",
      }));

      await uploadFilesToDatabase({
        serviceRequestId: serviceRequestOrigData?.id,
        documents,
      });
    }

    if (
      ["PENDING_APPROVAL"].includes(serviceRequestOrigData?.status) &&
      props.history?.location?.state?.approvalProcess
    ) {
      const serviceRequestResponse = await approveQuotation({
        serviceRequestId: params.id,
        ...payload,
      });
      if (serviceRequestResponse.status === 200) {
        dispatch(
          showAlert({
            message: "Service request approved successfully",
            type: "success",
          })
        );
        props.history.push("/service-request");
      }
    } else if (!props.history?.location?.state?.approvalProcess) {
      const serviceRequestResponse = await editServiceRequest({
        serviceRequestId: params.id,
        ...payload,
      });
      if (serviceRequestResponse.status === 200) {
        dispatch(
          showAlert({
            message: "Service request edited successfully",
            type: "success",
          })
        );
        props.history.push("/service-request");
      }
    }

    setIsLoading(false);
  };

  if (showLoading) {
    return <Skeleton width={100} />;
  }

  let lastStepButtonName = "Continue";

  return (
    <CreateServiceRequest
      currentStep={0}
      completedSteps={
        ["PENDING_APPROVAL"].includes(serviceRequestOrigData?.status) &&
        props.history?.location?.state?.approvalProcess
          ? -1
          : 3
      }
      onSubmitServiceRequest={_handleSubmit}
      isSubmitting={isLoading}
      initialServiceRequestData={serviceRequestData}
      requireAllFields={["COMPLETED", "PENDING_APPROVAL"].includes(
        serviceRequestOrigData?.status
      )}
      lastStepButtonName={lastStepButtonName}
      totalServiceCost={serviceRequestOrigData?.totalServiceCost}
      flowName="Edit"
      {...props}
    />
  );
}
