import * as ordersTypes from "./types";

const types = {
  ...ordersTypes,
};

type OrdersType = {
  list: any;
  totalOrders: number;
  totalPages: number;
  currentPage: number;
  orderSelected: String | null;
};

const initialState: OrdersType = {
  list: [],
  totalOrders: 0,
  totalPages: 0,
  currentPage: 0,
  orderSelected: null,
};

export default function ordersReducer(
  state = initialState,
  action: { type: String; payload: any }
) {
  switch (action.type) {
    case types.CHANGE_SELECTED_ORDER:
      return {
        ...state,
        orderSelected: action?.payload?.orderNumber,
      };
    case types.GET_ORDER_SUCCESS:
      return {
        ...state,
        list: action?.payload?.records,
        totalOrders: action?.payload?.totalCount,
        totalPages: action?.payload?.totalPages,
        currentPage: action?.payload?.currentPage,
      };
    case types.GET_ORDER_ERROR:
      return {
        ...state,
        list: 0,
        totalOrders: 0,
        totalPages: 0,
        currentPage: 0,
      };
    default:
      return state;
  }
}
