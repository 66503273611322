import {
  SwipeableDrawer,
  Typography,
  Paper,
  Tooltip,
  Slide,
} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Button } from "primereact/button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperDrawer: {
      top: 0,
      flex: "1 0 auto",
      height: "100%",
      display: "flex",
      outline: 0,
      zIndex: 1200,
      position: "fixed",
      overflowY: "auto",
      flexDirection: "column",
      width: "40%",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem",
    },
    noNewMessages: {
      padding: "1rem",
      display: "flex",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    messagesContainer: {
      overflowY: "auto",
      padding: "0 1rem 1rem 1rem",
    },
    message: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "0 0 1rem 1rem",
      marginBottom: "1rem",
      cursor: "pointer",
    },
  })
);

export default function Messages({
  newMessages,
  open,
  onClose,
  onOpen,
  deleteAllMessages,
  isDeletingAllMessages,
  deleteSingleMessageNotification,
  isDeletingMessageNoti,
  onMessageNotiClick,
}: any) {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      classes={{
        paper: classes.paperDrawer,
      }}
    >
      <div className={classes.header}>
        <Typography variant="h6">Message Notifications</Typography>

        {newMessages?.length ? (
          <Tooltip title="Clear All">
            <Button
              label="clear all"
              icon="pi pi-times"
              iconPos="right"
              loading={isDeletingAllMessages}
              className="p-button-danger p-button-text"
              onClick={deleteAllMessages}
            ></Button>
          </Tooltip>
        ) : null}
      </div>

      {newMessages.length ? (
        <div className={classes.messagesContainer}>
          {newMessages.map((newMessage: any) => (
            <Slide
              direction="left"
              in={true}
              mountOnEnter
              unmountOnExit
              key={newMessage.id}
            >
              <Paper
                className={classes.message}
                elevation={2}
                onClick={(e) => {
                  onMessageNotiClick(e, newMessage?.messageGroupId);
                }}
              >
                <div style={{ marginTop: "1rem", maxWidth: "90%" }}>
                  <Typography variant="subtitle2">
                    {newMessage?.message}
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ marginTop: "1rem" }}
                    color="textSecondary"
                  >
                    {moment(newMessage?.createdAt).format("MM/DD/YYYY hh:mm A")}
                  </Typography>
                </div>

                <Tooltip title="Clear Notification">
                  <Button
                    icon="pi pi-times"
                    disabled={isDeletingAllMessages}
                    className="p-button-danger p-button-text"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSingleMessageNotification(newMessage?.id);
                    }}
                    loading={newMessage?.id === isDeletingMessageNoti}
                  ></Button>
                </Tooltip>
              </Paper>
            </Slide>
          ))}
        </div>
      ) : (
        <div className={classes.noNewMessages}>
          <Typography>There are no new messages.</Typography>
        </div>
      )}
    </SwipeableDrawer>
  );
}
