import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import * as alertType from "./types";

const types = {
  ...alertType,
};


export const showAlert =
  (payload: {message: string, type: 'error'| 'warning' | 'success' | 'info'}): ThunkAction<void, any, unknown, AnyAction> =>
  (dispatch): void => {
    dispatch({ type: types.SHOW_ALERT, payload: payload });
  };

export const hideAlert =
  (): ThunkAction<void, any, unknown, AnyAction> =>
  (dispatch): void => {
    dispatch({
      type: types.RESET_ALERT,
    });
  };
