



import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core";
import CommingSoon from "../assets/images/comming-soon.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    }
  })
);

const UnderConstruction = () => {
    const classes = useStyles();
   
    return (
        <Grid container className={classes.root} spacing={4}>
            <Grid item sm={12} md={12}>
            <img
                src={CommingSoon}
                alt="Comming Soon"
                height="auto"
                width="100%"
            />
            </Grid>
        </Grid>
    )
}
export default UnderConstruction;