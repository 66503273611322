import { Grid, Typography, TextField, Button, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      margin: "40px 40px",
    },
    search: {
      marginTop: "20px",
      width: "80%",
    },
    button: {
      margin: "10px 0",
      width: 150,
    },
    questionsContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      margin: "40px 40px",
    },
    paper: {
      padding: theme.spacing(3),
      margin: "0 40px",
      marginBottom: "20px",
      borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
  })
);

export default function HelpCenter() {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid sm={12}>
        <div className={classes.container}>
          <Typography variant="h4">Need some help?</Typography>

          <TextField
            className={classes.search}
            variant="outlined"
            placeholder="What are you having trouble with?"
            type="search"
            InputProps={{
              endAdornment: (
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  GET HELP
                </Button>
              ),
            }}
          />
        </div>

        <div className={classes.questionsContainer}>
          <Typography variant="h5">Frequently Asked Questions</Typography>
        </div>
      </Grid>

      <Grid sm={12}>
        <Paper className={classes.paper}>
          <Typography>
            Q. Can I change the values of a Service request once I submit it?
          </Typography>
          <Typography>
            A. Yes, any values of a Service request can be editted as long as
            its not converted into an Order.
          </Typography>
        </Paper>
      </Grid>

      <Grid sm={12}>
        <Paper className={classes.paper}>
          <Typography>
            Q. How can i view the Order summary of an Order from order history
            page?
          </Typography>
          <Typography>
            A. Clicking on the Order number opens up a time line popup. You can
            then click on the Order Link (The second card) and you be get routed
            to order summary.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
