// import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Grid,
  // Breadcrumbs,
  // Link,
  Typography,
  Paper,
  // TableContainer,
  // TableHead,
  // TableRow,
  // TableCell,
  // Table,
  // TableBody,
  // TablePagination,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { BreadCrumb } from "primereact/breadcrumb";
import {
  makeStyles,
  createStyles,
  Theme,
  alpha,
} from "@material-ui/core/styles";
import { Button as PrimeReactButton } from "primereact/button";

// import { formatMoney } from "../../utils";
import { useState, useEffect } from "react";
import { getAssetRecoveryWorksheet, getOrderDataByID } from "../../services/orders.service";
import jsPDF from "jspdf";
import { GetApp } from "@material-ui/icons";
import * as htmlToImage from "html-to-image";
import moment from "moment";

import {
  previewFileFromFirebase,
  downloadFileFromFirebaseToLocal,
} from "../../services/documents.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    breadCrumbLink: {
      color: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.primary.light,
      },
    },
    paper: {
      padding: theme.spacing(2),
      // borderLeft: "5px solid #3f51b5",
      // "&:hover": {
      //   cursor: "pointer",
      // },
    },
    paperHeaderStyle: {
      color: theme.palette.text.primary,
      marginBottom: "0.5rem",
    },
    table: {},
    buttonStyle: {
      marginLeft: "40px",
    },
    buttonContainerStyle: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      background: "#FFFFFF",
      border: "1px solid #dee2e6",
      borderLeft: "0",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    header: {
      fontSize: "14px",
      fontWeight: "bold",
      marginRight: "2px",
    },
    value: {
      fontSize: "14px",
    },
    exportBtn: {
      borderRadius: "5px",
      padding: "0.5rem",
      color: "white",
      background: "#1769aa",
      marginRight: "1rem",
      "&:hover": {
        backgroundColor: alpha("#1769aa", 0.75),
      },
    },
    hiddenValue: {
      fontSize: "14px",
      color: "transparent",
    },
    rowStyle: {
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    comments: {
      maxWidth: 250,
    },
    orderStatusHeader: {
      color: theme.palette.text.primary,
      fontWeight: "bold",
      marginRight: "5px",
    },
    orderStatusValue: {
      color: theme.palette.text.primary,
    },
  })
);

type OrderSummaryProps = {
  orderDetails: any;
  history: {
    push: Function;
    goBack: Function;
  };
};

export default function OrderSummary({
  history,
  orderDetails,
}: OrderSummaryProps) {
  // const order = useSelector((state: any) =>
  //   state.orders?.list?.find(
  //     (x: any) => x.orderNumber === state.orders?.orderSelected
  //   )
  // );
  const classes = useStyles();
  const breadCrumbModelInit = [
    {
      label: "Orders",
      command: () => {
        history.push("/order-history");
      },
    },
  ];
  const home = {
    icon: "pi pi-home",
    command: () => history.push("/service-request"),
  };
  const [breadCrumbModel, setBreadcrumbModel] = useState<any>([
    ...breadCrumbModelInit,
  ]);
  const [order, setOrder] = useState<any>(null);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [isPreviewingFile, setIsPreviewingFile] = useState(false);
  const params = useParams<any>();

  // const _handleBreadCrumClick = (
  //   event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  // ) => {
  //   event.preventDefault();

  //   history.goBack();
  // };

  // const assignedDriver = order?.driverAndCargoDetails?.driverAssigned;

  // if (!order) {
  //   return <Redirect to="/orders" />;
  // }

  useEffect(() => {
    (async function fetchOrderDetails() {
      const orderDetailsResponse = await getOrderDataByID(params.id);
      if (orderDetailsResponse.status === 200) {
        const orderDetails = orderDetailsResponse.data;
        setOrder(orderDetails);
        setBreadcrumbModel([
          ...breadCrumbModelInit,
          {
            label: params.id,
          },
        ]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportPage = async () => {
    const input = document.getElementById("export_div") || document.body;
    const canvas = await htmlToImage.toCanvas(input);
    const imgWidth = 215;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "portrait");
    const position = 0;
    pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
    pdf.save("download.pdf");
  };

  
  const downLoadAssetRecoveryEvent = async () => {
    try {
      const response = await getAssetRecoveryWorksheet(params.id);
      if (response.status === 200) {
        downloadFileFromAssetRecovery(response,'asset_recovery_worksheet.pdf');
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  const downloadFileFromAssetRecovery = (blob: any, fileName:string) => {
    const file = new Blob([blob.data], {type: 'application/pdf'})
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

  const ItemTemplate = ({ doc }: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "40%" }}>
          <i
            className={`pi ${
              (doc?.documentName || doc.name)?.includes("pdf")
                ? "pi-file-pdf"
                : "pi-image"
            }`}
            style={{ fontSize: "2em" }}
          ></i>

          <span
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {doc?.documentName || doc.name}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <PrimeReactButton
            type="button"
            icon="pi pi-search-plus"
            className="p-button-primary"
            style={{
              marginRight: "1rem",
              color: "#fff",
            }}
            loading={isPreviewingFile === doc?.documentName}
            onClick={async () => {
              setIsPreviewingFile(doc?.documentName);
              await previewFileFromFirebase(doc.documentUrl, doc.documentName);
              setIsPreviewingFile(false);
            }}
          />

          <PrimeReactButton
            type="button"
            icon="pi pi-download"
            className="p-button-warning"
            style={{
              color: "#fff",
            }}
            loading={isDownloadingFile === doc?.documentName}
            onClick={async () => {
              setIsDownloadingFile(doc?.documentName);
              await downloadFileFromFirebaseToLocal(
                doc.documentUrl,
                doc.documentName
              );
              setIsDownloadingFile(false);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid container>
        <Grid item sm={4}>
          <BreadCrumb
            style={{ borderRight: "0" }}
            model={breadCrumbModel}
            home={home}
          />
          {/* <Breadcrumbs aria-label="breadcrumb" separator="-›">
            <Link
              color="inherit"
              href="/orders"
              onClick={_handleBreadCrumClick}
              className={classes.breadCrumbLink}
            >
              Orders
            </Link>
            <Typography color="textPrimary">{order && order.orderId || ''}</Typography>
          </Breadcrumbs> */}
        </Grid>

        <Grid item sm={8} className={classes.buttonContainerStyle}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "20px" }}
            onClick={() => history.push({
              pathname: `/order-tracker`,
            })}
          >
            Track Order
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "20px" }}
            onClick={downLoadAssetRecoveryEvent}
          >
            Asset Recovery Sheet
          </Button>

          <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: "20px" }}
            disabled
          >
            Pick up list
          </Button>

          <Tooltip title="Export">
            <IconButton
              color="primary"
              aria-label="export"
              size="small"
              onClick={exportPage}
              className={classes.exportBtn}
            >
              <GetApp />
              <Typography>Export As PDF</Typography>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid
        container
        id="export_div"
        style={{
          padding: "25px",
          background: "#fafafa",
          backgroundClip: "content-box",
        }}
        className={classes.root}
        spacing={4}
      >
        <Grid item sm={12}>
          <div className={classes.row}>
            <Typography className={classes.orderStatusHeader}>
              Order Status:
            </Typography>

            <Typography className={classes.orderStatusValue}>
              {(order && order.status) || "N/A"}
            </Typography>
          </div>
        </Grid>

        <Grid item sm={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeaderStyle}>
              Requested By
            </Typography>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Full Name:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {`${
                  (order &&
                    order?.customerDetails?.profileDetails?.firstName) ||
                  ""
                } ${
                  (order && order?.customerDetails?.profileDetails?.lastName) ||
                  ""
                }`}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Company Name:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order &&
                  order?.customerDetails?.profileDetails?.companyName) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Company Address:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {`${
                  order && order?.customerDetails?.profileDetails?.addressLine1
                }, ${
                  order && order?.customerDetails?.profileDetails?.addressLine2
                }, ${order && order?.customerDetails?.profileDetails?.city}, ${
                  order && order?.customerDetails?.profileDetails?.zipCode
                }`}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Phone Number:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order &&
                  order?.customerDetails?.profileDetails?.primaryPhoneNumber) ||
                  "N/A"}
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item sm={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeaderStyle}>
              Shipment Details
            </Typography>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Total Service Cost ($):
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order && order?.totalServiceCost) || "0"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Total Pallets:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order && order?.shipmentDetails?.totalPalletsRequired) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Items To Deliver:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order && order?.shipmentDetails?.itemsToDeliver) || "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Service Items:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order &&
                  order?.shipmentDetails?.serviceItems
                    .filter((data: any) => data.checked)
                    .map((data: any) => data.serviceName)
                    .join(",")) ||
                  "N/A"}
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeaderStyle}>
              Pick up Details
            </Typography>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Contact Name:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {`${
                  (order && order?.pickupDetails?.primaryProfile?.firstName) ||
                  ""
                } ${
                  (order && order?.pickupDetails?.primaryProfile?.lastName) ||
                  ""
                }`}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Contact Phone:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order &&
                  order?.pickupDetails?.primaryProfile?.primaryPhoneNumber) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Alternate Contact Name:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {`${
                  (order &&
                    order?.pickupDetails?.secondaryProfile?.firstName) ||
                  ""
                } ${
                  (order && order?.pickupDetails?.secondaryProfile?.lastName) ||
                  ""
                }`}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Alternate Contact Phone:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order &&
                  order?.pickupDetails?.secondaryProfile?.primaryPhoneNumber) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Company:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order && order?.pickupDetails?.primaryProfile?.companyName) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Address:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {`${
                  order && order?.pickupDetails?.primaryProfile?.addressLine1
                }, ${
                  order && order?.pickupDetails?.primaryProfile?.addressLine2
                }, ${order && order?.pickupDetails?.primaryProfile?.city}, ${
                  order && order?.pickupDetails?.primaryProfile?.zipCode
                }`}
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeaderStyle}>
              Destination Details
            </Typography>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Contact Name:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {`${
                  (order &&
                    order?.destinationDetails?.primaryProfile?.firstName) ||
                  ""
                } ${
                  (order &&
                    order?.destinationDetails?.primaryProfile?.lastName) ||
                  ""
                }`}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Contact Phone:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order &&
                  order?.destinationDetails?.primaryProfile
                    ?.primaryPhoneNumber) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Alternate Contact Name:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {`${
                  (order &&
                    order?.destinationDetails?.secondaryProfile?.firstName) ||
                  ""
                } ${
                  (order &&
                    order?.destinationDetails?.secondaryProfile?.lastName) ||
                  ""
                }`}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Alternate Contact Phone:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order &&
                  order?.destinationDetails?.secondaryProfile
                    ?.primaryPhoneNumber) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Company:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {(order &&
                  order?.destinationDetails?.primaryProfile?.companyName) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Address:
              </Typography>
              <Typography className={classes.value} style={{ width: "70%" }}>
                {`${
                  order &&
                  order?.destinationDetails?.primaryProfile?.addressLine1
                }, ${
                  order &&
                  order?.destinationDetails?.primaryProfile?.addressLine2
                }, ${
                  order && order?.destinationDetails?.primaryProfile?.city
                }, ${
                  order && order?.destinationDetails?.primaryProfile?.zipCode
                }`}
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item sm={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeaderStyle}>
              Assigned Crew
            </Typography>

            {order?.drivers.length ? (
              order?.drivers.map((driver: any) => (
                <>
                  <div className={classes.row}>
                    <Typography className={classes.header}>Driver:</Typography>
                    <Typography className={classes.value}>
                      {`${(driver && driver?.profile?.firstName) || ""} ${
                        (driver && driver?.profile?.lastName) || ""
                      }`}
                    </Typography>
                  </div>

                  <div className={classes.row}>
                    <Typography className={classes.header}>Phone:</Typography>
                    <Typography className={classes.value}>
                      {(driver && driver?.profile?.primaryPhoneNumber) || "N/A"}
                    </Typography>
                  </div>
                </>
              ))
            ) : (
              <>
                <div className={classes.row}>
                  <Typography className={classes.header}>Driver:</Typography>
                  <Typography className={classes.value}>N/A</Typography>
                </div>

                <div className={classes.row}>
                  <Typography className={classes.header}>Phone:</Typography>
                  <Typography className={classes.value}>N/A</Typography>
                </div>
              </>
            )}
          </Paper>
        </Grid>

        <Grid item sm={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeaderStyle}>
              Scheduled Time Of Pick Up
            </Typography>

            <div className={classes.row}>
              <Typography className={classes.header}>
                Scheduled Date:
              </Typography>
              <Typography className={classes.value}>
                {(order &&
                  order?.shipmentDetails?.scheduledDate &&
                  moment(order?.shipmentDetails?.scheduledDate).format(
                    "MM/DD/YYYY"
                  )) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header}>Arrival Time:</Typography>
              <Typography className={classes.value}>
                {(order &&
                  order?.shipmentDetails?.scheduledTimeFrom &&
                  order?.shipmentDetails?.scheduledTimeTo &&
                  `${order?.shipmentDetails?.scheduledTimeFrom}-${order?.shipmentDetails?.scheduledTimeTo}`) ||
                  "N/A"}
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item sm={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeaderStyle}>
              Instructions
            </Typography>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                General Instructions:
              </Typography>
              <Typography className={classes.value}>
                {(order && order?.pickupDetails?.generalInstructions) || "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                Prior To Pick up:
              </Typography>
              <Typography className={classes.value}>
                {(order && order?.pickupDetails?.priorToPickupInstructions) ||
                  "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                OnSite:
              </Typography>
              <Typography className={classes.value}>
                {(order && order?.pickupDetails?.onSiteInstructions) || "N/A"}
              </Typography>
            </div>

            <div className={classes.row}>
              <Typography className={classes.header} style={{ width: "30%" }}>
                After Pick up:
              </Typography>
              <Typography className={classes.value}>
                {(order && order?.pickupDetails?.afterPickUpInstructions) ||
                  "N/A"}
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item sm={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeaderStyle}>
              Documents Uploaded
            </Typography>

            {order?.documents?.length ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    border: "1px solid lightgrey",
                    padding: "1rem 1rem 0 1rem",
                    borderRadius: "5px",
                  }}
                >
                  {order?.documents.map((document: any) => (
                    <ItemTemplate
                      key={document?.id || document?.name}
                      doc={document}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <Typography>No Documents Uploaded</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}
