import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to add a new service request
 * @returns
 */
export const createServiceRequest = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/service-request/create`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to approve a service request
 * @returns 
 */
export const approveQuotation = async (payload: any) => {
    try {
        return await Axios.post(`${process.env.REACT_APP_API_BASE_URL}/service-request/approve-service-request`, payload);
    } catch (error: any) {
        return {status: 500, data: null, statusText: 'Oops! something went wrong'}
    }
};
/**
 * This function is used to fetch a service request
 * @returns
 */
export const fetchServiceRequest = async (serviceRequestID: string) => {
  try {
    return await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/service-request/get?request_id=${serviceRequestID}`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to edit a service request
 * @returns 
 */
 export const editServiceRequest = async (payload: any) => {
  try {
      return await Axios.post(`${process.env.REACT_APP_API_BASE_URL}/service-request/edit-service-request`, payload);
  } catch (error: any) {
      return {status: 500, data: null, statusText: 'Oops! something went wrong'}
  }
};