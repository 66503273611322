import { UserType } from "../user/reducer";

type UsersType = {
  list: UserType[];
};

const initialState: UsersType = {
  list: [],
};

export default function usersReducer(state = initialState) {
  return state;
}
