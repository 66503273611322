import React, { useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  StepConnector,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  LocalShipping,
  AssignmentTurnedIn,
  ShoppingCart,
  BeenhereOutlined,
  Check,
} from "@material-ui/icons";
import clsx from "clsx";
import { Button } from "primereact/button";

import PickupDetailsForm from "./PickupDetailsForm";
import DestinationDetailsForm from "./DestinationDetailsForm";
import InstructionsForm from "./InstructionsForm";
import ShipmentDetails from "./ShipmentDetails";
import Confirmation from "./Confirmation";
import { formatServiceRequestDataForForm } from "./formatServiceRequestDataForForms";

const useStyles = makeStyles({
  stepperContainer: {
    width: "80%",
    background: "none",
    padding: 0,
    margin: 0,
  },
  stepperContentContainer: {
    width: "80%",
    marginTop: "1rem",
    padding: "2rem 2rem 0 2rem",
    marginBottom: "0.1rem",
  },
  stepperButtonsContainer: {
    width: "80%",
    marginTop: "1rem",
    padding: "0.5rem 1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "none",
    boxShadow: "none",
  },
  carousalIndicators: {
    display: "none",
  },
});

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 38,
    height: 38,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

export default function CreateServiceRequest({
  history,
  currentStep,
  completedSteps,
  initialServiceRequestData,
  onSubmitServiceRequest,
  isSubmitting,
  requireAllFields,
  lastStepButtonName,
  totalServiceCost,
  flowName,
}: any) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepsCompleted, setStepsCompleted] = useState<number>(-1);
  const [serviceRequestData, setServiceRequestData] = useState<any>(
    formatServiceRequestDataForForm({
      pickupDetails: {},
      destinationDetails: {},
      instructions: {},
      shipmentDetails: {},
      confirmation: false,
    })
  );
  const [currentFormRef, setCurrentFormRef] = useState<any>(null);

  const classes = useStyles();

  useEffect(() => {
    if (currentStep) {
      setActiveStep(currentStep);
    }
    if (completedSteps) {
      setStepsCompleted(completedSteps);
    }
    if (initialServiceRequestData) {
      setServiceRequestData(initialServiceRequestData);
    }
  }, [currentStep, completedSteps, initialServiceRequestData]);

  const handleEachStepNext = (
    formName:
      | "pickupDetails"
      | "destinationDetails"
      | "instructions"
      | "shipmentDetails",
    data: any,
    onlySaveData?: boolean
  ) => {
    const newServiceRequestData = {
      ...serviceRequestData,
    };
    newServiceRequestData[formName] = {
      ...newServiceRequestData[formName],
      ...data,
    };

    setServiceRequestData(newServiceRequestData);

    if (!onlySaveData) {
      setActiveStep((prevState) => prevState + 1);

      if (stepsCompleted < activeStep) {
        setStepsCompleted(activeStep);
      }
    }
  };

  const ColorlibStepIcon = (Icon: JSX.Element, iconPosition: number) => {
    const iconClasses = useColorlibStepIconStyles();

    return (
      <div
        className={clsx(iconClasses.root, {
          [iconClasses.active]:
            iconPosition <= stepsCompleted || activeStep === iconPosition,
          [iconClasses.completed]: iconPosition <= stepsCompleted,
        })}
      >
        {Icon}
      </div>
    );
  };

  const steps = [
    {
      id: 0,
      formName: "pickupDetails",
      stepName: "Pickup  Details",
      Component: PickupDetailsForm,
      Icon: stepsCompleted >= 0 ? <Check /> : <LocalShipping />,
    },
    {
      id: 1,
      formName: "destinationDetails",
      stepName: "Destination Details",
      Component: DestinationDetailsForm,
      Icon: stepsCompleted >= 1 ? <Check /> : <LocalShipping />,
    },
    {
      id: 2,
      formName: "instructions",
      stepName: "Instructions",
      Component: InstructionsForm,
      Icon: stepsCompleted >= 2 ? <Check /> : <AssignmentTurnedIn />,
    },
    {
      id: 3,
      formName: "shipmentDetails",
      stepName: "Shipment Details",
      Component: ShipmentDetails,
      Icon: stepsCompleted >= 3 ? <Check /> : <ShoppingCart />,
    },
    {
      id: 4,
      stepName: "Confirmation",
      formName: "confirmation",
      Component: Confirmation,
      Icon: stepsCompleted >= 4 ? <Check /> : <BeenhereOutlined />,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={activeStep <= stepsCompleted ? stepsCompleted : activeStep}
        connector={<ColorlibConnector />}
        className={classes.stepperContainer}
      >
        {steps.map(
          (
            { stepName, Icon }: { stepName: string; Icon: JSX.Element },
            index: number
          ): JSX.Element => (
            <Step key={stepName}>
              <StepButton
                onClick={() => {
                  setActiveStep(index);
                }}
                completed={index <= stepsCompleted}
              >
                <StepLabel
                  StepIconComponent={() => ColorlibStepIcon(Icon, index)}
                >
                  {stepName}
                </StepLabel>
              </StepButton>
            </Step>
          )
        )}
      </Stepper>

      <Paper className={classes.stepperContentContainer}>
        {(() => {
          const {
            id,
            formName,
            Component,
          }: {
            id: number;
            formName:
              | "pickupDetails"
              | "destinationDetails"
              | "instructions"
              | "shipmentDetails"
              | "confirmation"
              | string;
            Component: (props: any) => JSX.Element;
          } = steps[activeStep];

          return (
            <Component
              currentFormRef={id === activeStep ? setCurrentFormRef : () => {}}
              handleEachStepNext={handleEachStepNext}
              formName={formName}
              initialValues={serviceRequestData[formName]}
              serviceRequestData={serviceRequestData}
              requireAllFields={requireAllFields}
              totalServiceCost={totalServiceCost}
              flowName={flowName}
            />
          );
        })()}
      </Paper>

      <Paper className={classes.stepperButtonsContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            label="Cancel"
            icon="pi pi-times"
            style={{
              marginRight: "1rem",
              borderColor: "#d65c49",
              backgroundColor: "#d65c49",
            }}
            onClick={() => {
              history.push("/service-request");
            }}
            disabled={isSubmitting}
          />

          <Button
            label="Previous"
            icon="pi pi-angle-left"
            style={{
              backgroundColor: "#1769aa",
            }}
            onClick={() => {
              setActiveStep((prevState) => prevState - 1);
            }}
            disabled={activeStep === 0}
          />
        </div>

        <Button
          label={
            activeStep === steps.length - 1
              ? lastStepButtonName || "Submit"
              : "Next"
          }
          icon="pi pi-angle-right"
          iconPos="right"
          style={{
            backgroundColor: "#1769aa",
          }}
          onClick={() => {
            if (activeStep === steps.length - 1) {
              onSubmitServiceRequest?.(serviceRequestData);
              return;
            }

            currentFormRef?.handleSubmit();
          }}
          loading={isSubmitting}
        />
      </Paper>
    </div>
  );
}
