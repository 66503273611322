import * as ordersTypes from "./types";

const types = {
  ...ordersTypes,
};

type OrdersType = {
  list: any;
  totalServiceRequests: number;
  totalPages: number;
  currentPage: number;
  serviceRequestSelected: String | null;
};

const initialState: OrdersType = {
  list: [],
  totalServiceRequests: 0,
  totalPages: 0,
  currentPage: 1,
  serviceRequestSelected: null,
};

export default function serviceRequestReducer(
  state = initialState,
  action: { type: String; payload: any }
) {
  switch (action.type) {
    case types.CHANGE_SELECTED_SERVICE_REQUEST:
      return {
        ...state,
        serviceRequestSelected: action?.payload?.orderNumber,
      };
    case types.FETCH_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        totalServiceRequests: action.payload.totalCount,
        list: action.payload.records,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case types.FETCH_SERVICE_REQUEST_FAILED:
      return {
        ...state,
        list: [],
        totalServiceRequests: 0,
        totalPages: 0,
        currentPage: 0,
      };
    default:
      return state;
  }
}
