import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PrimeReact from "primereact/api";

import configureStore from "./config/configureRedux";
import AppWithTheme from "./AppWithTheme";

const { store, persistor } = configureStore();

function App() {
  PrimeReact.ripple = true;
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppWithTheme />
      </PersistGate>
    </Provider>
  );
}

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./routes", App);
}

export default App;
