import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to fetch service items
 * @returns
 */
export const getServiceItems = async () => {
  try {
    return await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/service-item/get`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to add a new service item
 * @returns
 */
export const addServiceItem = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/service-item/create`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to delete a service item
 * @returns
 */
export const deleteServiceItem = async (serviceItemID: string) => {
  try {
    return await Axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/service-item/delete?serviceId=${serviceItemID}`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
