import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Link,
  alpha,
  Paper,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { formatMoney } from "accounting";
import { GetApp, Search, Cached, Assignment,FilterList } from "@material-ui/icons";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { getAllOrders } from "../../../redux/orders/actions";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import TimelineComponent from "./Timeline";
import { OverlayPanel } from "primereact/overlaypanel";
import { OrderSummaryPanel } from "../OrderSummaryPanel";
import { Checkbox } from "primereact/checkbox";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {},
    paper: {
      padding: theme.spacing(2),
    },
    outlinedButtonStyle: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
      },
    },
    search: {
      position: "relative",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    rowStyle: {
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    linkStyle: {
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    timelineContainer: {
      position: "absolute",
      width: "80%",
      padding: theme.spacing(2),
      maxHeight: "90%",
      overflow: "scroll",
    },
    refreshbtn: {
      borderRadius: "5px",
      color: "white",
      background: "#d65c49",
      marginRight: "1rem",
      padding: "0 0.5rem",
      "&:hover": {
        backgroundColor: alpha("#d65c49", 0.75),
      },
    },
    exportBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#1769aa",
      marginRight: "1rem",
      "&:hover": {
        backgroundColor: alpha("#1769aa", 0.75),
      },
    },
    filterBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      paddingLeft: "0.5rem",
      marginRight: '1rem',
      background: "#ff9800",
      "&:hover": {
        backgroundColor: alpha("#ff9800", 0.75),
      },
    },
    buttonContainerStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      height: "100%",
      padding: 0,
    },
    dataTableColumnStyle: {
      fontSize: "0.9rem",
      padding: "0.75rem !important",
      border: "1px solid #e9ecef !important",
    },
    dataTableHeaderStyle: {
      padding: "0.75rem !important",
      // border: "1px solid lightgrey !important",
      //backgroundColor: `${theme.palette.primary.main} !important`,
      //color: "#fff !important",
      whiteSpace: "nowrap",
    },
    dataTableRowStyle: {
      "&:nth-child(even)": {
        backgroundColor: "#BDBDBD !important",
      },
    },
    searchFieldStyle: {
      padding: "0.4rem 0 0.5rem 0.5rem",
    },
  })
);

type OrdersProps = {
  history: {
    push: Function;
    replace: Function;
  };
};

const statusOptions = [
  { name: "Created", code: "CREATED" },
  { name: "Crew Assigned", code: "CREW_ASSIGNED" },
];

export default function OrderHistory({ history }: OrdersProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [orderForTimeline, setOrderForTimeline] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [filterByState, setFilterBy] = useState<any>('');
  const [orderTimelineModalVisible, setOrderTimelineModalVisible] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [orderSummaryModalVisible, setOrderSummaryModalVisible] =
    useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  
  // Paginations states
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const onPageChage = (event: any) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    setRowsPerPage(event.rows);
  };

  const listOfOrders = useSelector((state: any) => state?.orders?.list);

  const totalCount = useSelector((state: any) => state?.orders?.totalOrders);

  const menu = useRef<any>();
  const dataTableRef = useRef<any>();
  const overlayRef = useRef<any>();
  const filterMenuRef = useRef<any>();


  const determineQuery = () => {
    return new URLSearchParams(window.location.search);
  }
  

  const determineState = (status:string | null) => {
    switch (status) {
      case 'completed':
        return ["COMPLETED"];
      case 'in_progress':
        return ["CREATED"];
      default: 
        return '';
    } 
  }
  /**
   * component did mount.
   */
  useEffect(() => {
    (async function fetchOrders() {
      setLoading(true);
      let query = determineQuery();
      let filterData = filterByState;
      if (query.get('status')) {
        filterData = JSON.stringify({status: determineState(query.get('status'))})
        setFilterBy(filterData);
        setSelectedStatus(determineState(query.get('status')));
        query.delete('status')
        history.replace({
          search: query.toString(),
        })
      }
      await dispatch(getAllOrders(searchText, currentPage, rowsPerPage,filterData));
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);

  /**
   * on search event of filter data table.
   */
  const searchEvent = async () => {
    setLoading(true);
    await dispatch(getAllOrders(searchText, currentPage, rowsPerPage, filterByState));
    setLoading(false);
  };

  /**
   * Export as csv
   */
  const exportCSV = () => {
    if (dataTableRef && dataTableRef.current) {
      dataTableRef.current.exportCSV();
    }
  };

  const exportColumns = listOfOrders?.[0]
    ? Object.keys(listOfOrders?.[0]).reduce((a: any, c: string) => {
        const result = c?.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        a.push({
          title: finalResult,
          dataKey: c,
        });

        return a;
      }, [])
    : [];

  /**
   * Export as pdf
   */
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, listOfOrders);
        doc.save("workOrders.pdf");
      });
    });
  };

  const saveAsExcelFile = (buffer: any, fileName: string) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  /**
   * Export as excel
   */
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(listOfOrders);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "workOrders");
    });
  };

  /**
   * On click event of order id from the table.
   * @param row
   */
  const openTimeLine = (row: any) => {
    setOrderForTimeline(row);
    setOrderTimelineModalVisible(true);
  };

  const onCloseTimelineModal = (event: {
    show: boolean;
    refreshPage: boolean;
  }) => {
    setOrderForTimeline(null);
    setOrderTimelineModalVisible(event.show);
  };

  const filterBy = (e: any) => {
    let selectedStatusClone = [...selectedStatus];
    if(e.checked)
      selectedStatusClone.push(e.value);
    else
    selectedStatusClone.splice(selectedStatusClone.indexOf(e.value), 1);
    
    setSelectedStatus(selectedStatusClone);
    
    const filterByClone = JSON.stringify({[e.target.id]: selectedStatusClone});
    setFilterBy(filterByClone);

    (async function fetchOrders() {
      setLoading(true);
      await dispatch(
        getAllOrders(
          searchText,
          currentPage,
          rowsPerPage,
          filterByClone
        )
      );
      setLoading(false);
    })();
  };

  /**
   * Action body template
   * @param row
   * @returns
   */
  const actionBody = (row: any) => (
    <div style={{ display: "inline-flex" }}>
      <Tooltip title="View">
        <IconButton
          color="primary"
          onClick={(event: any) => redirectTo(event, row)}
          size="small"
        >
          <Assignment />
        </IconButton>
      </Tooltip>
    </div>
  );

  const redirectTo = (event: any, row: any) => {
    event.stopPropagation();
    event.preventDefault();
    setOrderSummaryModalVisible(true);
    setSelectedRow(row);
    overlayRef && overlayRef.current && overlayRef.current.toggle(event);
  };

  const statusColor = (rowData: any) => {
      let color = "#388e3c";

      if (rowData.code === "CREATED") {
        color = "#f7773c";
      }

      if (rowData.code === "DRIVER_ASSIGNED") {
        color = "#d65c49";
      }

      return (
        <span
          style={{
            padding: "0.5rem",
            backgroundColor: color,
            fontSize: "0.85rem",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          {rowData.name}
        </span>
      );
  }

  /**
   * Header template for the orders data table
   */
  const headerOrdersTable = (
    <Grid container>
      <Grid
        item
        sm={12}
        md={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Tooltip title="Refresh Data">
          <IconButton
            color="primary"
            aria-label="refresh"
            size="small"
            onClick={searchEvent}
            className={classes.refreshbtn}
          >
            <Cached />
          </IconButton>
        </Tooltip>

        <Tooltip title="Filter">
          <IconButton
            color="primary"
            aria-label="export"
            size="small"
            onClick={(event) => filterMenuRef.current.toggle(event)}
            className={classes.filterBtn}
          >
            <FilterList />
            <Typography>Filter</Typography>
          </IconButton>
        </Tooltip>

        <Tooltip title="Export">
          <IconButton
            color="primary"
            aria-label="export"
            size="small"
            onClick={(event) => menu.current.toggle(event)}
            className={classes.exportBtn}
          >
            <GetApp />
            <Typography>Export</Typography>
          </IconButton>
        </Tooltip>

        <Menu 
          ref={filterMenuRef}
          popup
          id="filtermenu"
          model={[
          {
            template: () => {
                return (
                  <ul className="p-menu-list p-reset" role="menu">
                    {
                      statusOptions && statusOptions.map((option: any, index: number) => (
                        <li className="p-menuitem" role="none" key={index}>
                          <a className="p-menuitem-link">
                            <span className="p-menuitem-icon">
                              <Checkbox 
                                id="status"
                                value={option.code}
                                onChange={filterBy}
                                checked={selectedStatus.includes(option.code)}
                              />
                            </span>
                            <span className="p-menuitem-text" style={{marginLeft: '10px'}}>{statusColor(option)}</span>
                          </a>
                        </li>
                      ))
                    }
                  </ul>
                );
            }
          }
        ]}/>

        <Menu
          model={[
            {
              label: "Export as CSV",
              icon: "pi pi-file-o",
              command: () => exportCSV(),
            },
            {
              label: "Export as Excel",
              icon: "pi pi-file-excel",
              command: () => exportExcel(),
            },
            {
              label: "Export as PDF",
              icon: "pi pi-file-pdf",
              command: () => exportPdf(),
            },
          ]}
          popup
          ref={menu}
          id="popup_menu"
        />

        <TextField
          placeholder="Search…"
          variant="outlined"
          onChange={(event: any) => setSearchText(event.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              searchEvent();
            }
          }}
          inputProps={{
            className: classes.searchFieldStyle,
          }}
          InputProps={{
            style: {
              padding: 0,
            },
            endAdornment: (
              <InputAdornment position="end" style={{ padding: 0, margin: 0 }}>
                <IconButton
                  onClick={searchEvent}
                  style={{
                    backgroundColor: "#388e3c",
                    padding: "0.25rem",
                    margin: 0,
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  const orderIDBody = (row: any) => (
    <Link
      className={classes.linkStyle}
      onClick={() => openTimeLine(row)}
      // onClick={(
      //   event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
      // ) => {
      //   event.preventDefault();
      //   setIsTimeLineOpen(row.orderNumber);
      // }}
    >
      {row.orderId}
    </Link>
  );

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={12} md={12}>
        <Paper style={{ padding: "0.25rem 1rem" }}>
          <DataTable
            ref={dataTableRef}
            loading={loading}
            value={listOfOrders}
            sortMode="multiple"
            dataKey="id"
            resizableColumns
            columnResizeMode="expand"
            tableStyle={{
              position: "relative",
              tableLayout: "auto",
              overflow: "auto",
              minWidth: "1000px",
              width: "100%",
            }}
            header={headerOrdersTable}
          >
            <Column
              field="orderId"
              header="Order Number"
              sortable
              body={orderIDBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="customOrderId"
              header="Custom Order Id"
              sortable 
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="serviceRequestId"
              header="Service Request ID"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="pickupLocation"
              header="Pickup Location"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            {/* <Column
              field="destinationLocation"
              header="Destination Location"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column> */}
            <Column
              field="totalServiceCost"
              header="Service Cost($)"
              body={(values) =>
                values?.totalServiceCost
                  ? formatMoney(values?.totalServiceCost, "$", 2)
                  : "N/A"
              }
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="totalWeight"
              header="Total Weight(Lbs)"
              sortable
              body={(values) =>
                formatMoney(Number(values.totalWeight), "", 0) + "lbs"
              }
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column field="createdBy" header="Requested By" sortable></Column>
            <Column
              field="status"
              header="Status"
              sortable
              body={(rowData: any) => {
                let color = "#388e3c";

                if (rowData.status === "CREATED") {
                  color = "#f7773c";
                }

                if (rowData.status === "DRIVER_ASSIGNED") {
                  color = "#d65c49";
                }

                return (
                  <span
                    style={{
                      padding: "0.5rem",
                      backgroundColor: color,
                      fontSize: "0.85rem",
                      color: "#fff",
                      borderRadius: "5px",
                    }}
                  >
                    {rowData.status}
                  </span>
                );
              }}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              header="Actions"
              body={actionBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
          </DataTable>
          <OverlayPanel
            showCloseIcon
            ref={overlayRef}
            style={{ width: "50%" }}
            className="overlaypanel-demo"
          >
            <OrderSummaryPanel orderDetails={selectedRow} />
          </OverlayPanel>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paginator
              first={first}
              rows={rowsPerPage}
              totalRecords={totalCount}
              rowsPerPageOptions={[5, 10, 15, 20]}
              onPageChange={onPageChage}
            ></Paginator>

            <Typography>Total Records: {totalCount}</Typography>
          </div>
        </Paper>
      </Grid>
      {orderForTimeline && orderTimelineModalVisible && (
        <TimelineComponent
          orderDetails={orderForTimeline}
          modalVisible={orderTimelineModalVisible}
          handleClose={onCloseTimelineModal}
        />
      )}
    </Grid>
  );
}
