import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Typography } from "@material-ui/core";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import {
  previewFileFromFirebase,
  downloadFileFromFirebaseToLocal,
  deleteFileFromFirebaseAndDatabase,
} from "../../../services/documents.service";
import DeleteConfirmationModal from "./DeleteConfirmation";
import { Messages } from "primereact/messages";

type instructionsType = {
  generalInstructions: string;
  priorToPickupInstructions: string;
  onSiteInstructions: string;
  afterPickupInstructions: string;
};

export default function InstructionsForm({
  initialValues,
  formName,
  handleEachStepNext,
  currentFormRef,
  flowName,
}: any) {
  const [totalSize, setTotalSize] = useState(0);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [isPreviewingFile, setIsPreviewingFile] = useState(false);
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<any>(null);
  const [files, setFiles] = useState<any>([]);
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);
  const fileUploadMessages = useRef<any>(null);
  const serviceRequestId = useSelector((state: any) => {
    const serviceRequestIdString = state.serviceRequests.serviceRequestSelected;
    const serviceRequest = state.serviceRequests.list.find(
      (x: any) => x.requestId === serviceRequestIdString
    );

    return serviceRequest?.id;
  });

  const formik = useFormik({
    initialValues: initialValues || {
      generalInstructions: "",
      priorToPickupInstructions: "",
      onSiteInstructions: "",
      afterPickupInstructions: "",
    },
    validate: (data: instructionsType) => {
      let errors: any = {};

      if (totalSize > 3000000) {
        errors.fileUpload = 'The file limit should be 3 MB.'
        fileUploadMessages.current.show(
          { severity: 'error', summary: 'Oops!', detail: 'The file limit should be 3 MB.', life: 2000 }
        );
      }
      return errors;
    },
    onSubmit: (data: instructionsType) => {
      handleEachStepNext(formName, {
        ...data,
        documents: initialValues.documents?.length
          ? initialValues.documents.concat(files)
          : [].concat(files),
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    currentFormRef(formik);
    if (initialValues?.documents?.length) {
      let _totalSize = 0;
    
      initialValues?.documents?.forEach((_file: File) => {
        _totalSize += _file.size;
      })
  
      setTotalSize(_totalSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type instructionsKeyType =
    | "generalInstructions"
    | "priorToPickupInstructions"
    | "onSiteInstructions"
    | "afterPickupInstructions";

  const isFormFieldValid = (name: instructionsKeyType) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: instructionsKeyType) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const onTemplateSelect = (e: any) => {
    let _totalSize = initialValues?.documents?.length ? totalSize : 0;
    
    fileUploadRef.current.files.forEach((_file: File) => {
      _totalSize += _file.size;
    })

    setFiles(Object.values(e.files));

    setTotalSize(_totalSize);
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    setFiles(files.filter((_file:File) => file.name !== _file.name));
    callback();
  };

  const headerTemplate = (options: any) => {
    const {
      className,
      chooseButton,
      //uploadButton, cancelButton
    } = options;
    const value = totalSize / 30000;
    const formatedValue = fileUploadRef?.current
      ? fileUploadRef.current.formatSize(totalSize)
      : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {/* {uploadButton}
        {cancelButton} */}
        <ProgressBar
          value={value}
          displayValueTemplate={() => `${formatedValue} / 3 MB`}
          style={{ width: "300px", height: "25px", marginLeft: "auto" }}
        ></ProgressBar>
      </div>
    );
  };

  const ItemTemplate = ({ doc }: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "40%" }}>
          <i
            className={`pi ${
              (doc?.documentName || doc?.name)?.includes("pdf")
                ? "pi-file-pdf"
                : "pi-image"
            }`}
            style={{ fontSize: "2em" }}
          ></i>

          <span
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {doc?.documentName || doc?.name}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            type="button"
            icon="pi pi-search-plus"
            className="p-button-primary"
            style={{
              marginRight: "1rem",
              color: "#fff",
            }}
            loading={isPreviewingFile === doc?.documentName}
            onClick={async () => {
              if (flowName !== "Create") {
                setIsPreviewingFile(doc?.documentName);
                await previewFileFromFirebase(
                  doc.documentUrl,
                  doc.documentName
                );
                setIsPreviewingFile(false);
              } else {
                var fileURL = window.URL.createObjectURL(doc);
                let tab: any = window.open("_blank");
                tab.location.href = fileURL;
              }
            }}
          />

          <Button
            type="button"
            icon="pi pi-download"
            className="p-button-warning"
            style={{
              marginRight: "1rem",
              color: "#fff",
            }}
            loading={isDownloadingFile === doc?.documentName}
            onClick={async () => {
              if (flowName !== "Create") {
                setIsDownloadingFile(doc?.documentName);
                await downloadFileFromFirebaseToLocal(
                  doc.documentUrl,
                  doc.documentName
                );
                setIsDownloadingFile(false);
              } else {
                const url = window.URL.createObjectURL(new Blob([doc]));
                const link: any = document.createElement("a");
                link.href = url;
                link.setAttribute("download", doc.name);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
              }
            }}
          />

          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-danger"
            style={{
              color: "#fff",
            }}
            loading={isDeletingFile === doc?.documentName}
            onClick={() => {
              setShowDeleteConfirmation(doc);
            }}
          />
        </div>
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "40%" }}>
          <i
            className={`pi ${
              file.name.includes("pdf") ? "pi-file-pdf" : "pi-image"
            }`}
            style={{ fontSize: "2em" }}
          ></i>

          <span
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tag
            value={props.formatSize}
            severity="warning"
            style={{
              padding: "0.5rem",
              marginRight: "1rem",
            }}
          />
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
            onClick={() => onTemplateRemove(file, props.onRemove)}
          />
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <i
          className="pi pi-image p-mt-3 p-p-5"
          style={{
            fontSize: "5em",
            padding: "0.5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{
            fontSize: "1.2em",
            color: "var(--text-color-secondary)",
            margin: "1rem 0",
          }}
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    // iconOnly: true,
    className: "custom-choose-btn",
    style: {
      backgroundColor: "#1769aa",
    },
  };

  return (
    <form
      style={{ width: "100%", paddingBottom: "0.5rem" }}
      onSubmit={formik.handleSubmit}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="p-field" style={{ width: "100%", marginRight: "1rem" }}>
          <span className="p-float-label">
            <InputTextarea
              id="generalInstructions"
              name="generalInstructions"
              value={formik.values.generalInstructions}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("generalInstructions"),
              })}
              autoResize
              style={{
                width: "100%",
                maxHeight: "5rem",
                overflowX: "hidden",
                overflowY: "auto",
                scrollbarWidth: "thin",
                color: "black",
                opacity: 1,
              }}
            />
            <label
              htmlFor="generalInstructions"
              className={classNames({
                "p-error": isFormFieldValid("generalInstructions"),
              })}
            >
              General Instructions
            </label>
          </span>
          {getFormErrorMessage("generalInstructions")}
        </div>

        <div className="p-field" style={{ width: "100%", marginLeft: "1rem" }}>
          <span className="p-float-label">
            <InputTextarea
              id="priorToPickupInstructions"
              name="priorToPickupInstructions"
              value={formik.values.priorToPickupInstructions}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("priorToPickupInstructions"),
              })}
              autoResize
              style={{
                width: "100%",
                maxHeight: "5rem",
                overflowX: "hidden",
                overflowY: "auto",
                scrollbarWidth: "thin",
                color: "black",
                opacity: 1,
              }}
            />
            <label
              htmlFor="priorToPickupInstructions"
              className={classNames({
                "p-error": isFormFieldValid("priorToPickupInstructions"),
              })}
            >
              Prior to pickup instructions
            </label>
          </span>
          {getFormErrorMessage("priorToPickupInstructions")}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="p-field" style={{ width: "100%", marginRight: "1rem" }}>
          <span className="p-float-label">
            <InputTextarea
              id="onSiteInstructions"
              name="onSiteInstructions"
              value={formik.values.onSiteInstructions}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("onSiteInstructions"),
              })}
              autoResize
              style={{
                width: "100%",
                maxHeight: "5rem",
                overflowX: "hidden",
                overflowY: "auto",
                scrollbarWidth: "thin",
                color: "black",
                opacity: 1,
              }}
            />
            <label
              htmlFor="onSiteInstructions"
              className={classNames({
                "p-error": isFormFieldValid("onSiteInstructions"),
              })}
            >
              On site instructions
            </label>
          </span>
          {getFormErrorMessage("onSiteInstructions")}
        </div>

        <div className="p-field" style={{ width: "100%", marginLeft: "1rem" }}>
          <span className="p-float-label">
            <InputTextarea
              id="afterPickupInstructions"
              name="afterPickupInstructions"
              value={formik.values.afterPickupInstructions}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("afterPickupInstructions"),
              })}
              autoResize
              style={{
                width: "100%",
                maxHeight: "5rem",
                overflowX: "hidden",
                overflowY: "auto",
                scrollbarWidth: "thin",
                color: "black",
                opacity: 1,
              }}
            />
            <label
              htmlFor="afterPickupInstructions"
              className={classNames({
                "p-error": isFormFieldValid("afterPickupInstructions"),
              })}
            >
              After pick up instructions
            </label>
          </span>
          {getFormErrorMessage("afterPickupInstructions")}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Upload Documents
        </Typography>

        {initialValues?.documents?.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                padding: "1rem 1rem 0 1rem",
                borderRadius: "5px",
              }}
            >
              {initialValues?.documents.map((document: any) => (
                <ItemTemplate key={document.id} doc={document} />
              ))}
            </div>
          </div>
        ) : null}

        <Toast ref={toast}></Toast>

        <FileUpload
          ref={fileUploadRef}
          name="demo[]"
          url="https://primefaces.org/primereact/showcase/upload.php"
          multiple
          accept=".png,.jpeg,.pdf"
          maxFileSize={3000000}
          // onUpload={onTemplateUpload}
          onSelect={onTemplateSelect}
          onError={onTemplateClear}
          onClear={onTemplateClear}
          headerTemplate={headerTemplate}
          itemTemplate={itemTemplate}
          emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions}
        />

        <Messages ref={fileUploadMessages} />
      </div>

      <DeleteConfirmationModal
        open={Boolean(showDeleteConfirmation)}
        handleClose={() => {
          setShowDeleteConfirmation(false);
        }}
        onDelete={async () => {
          setShowDeleteConfirmation(false);
          if (flowName !== "Create") {
            setIsDeletingFile(showDeleteConfirmation?.documentName);
            await deleteFileFromFirebaseAndDatabase(
              showDeleteConfirmation?.documentUrl,
              serviceRequestId,
              showDeleteConfirmation?.id
            );
            handleEachStepNext(
              formName,
              {
                ...formik.values,
                documents: initialValues.documents.filter(
                  (x: any) => x.id !== showDeleteConfirmation.id
                ),
              },
              true
            );

            setIsDeletingFile(false);
          } else {
            if (showDeleteConfirmation.objectUrl) {
              handleEachStepNext(
                formName,
                {
                  ...formik.values,
                  documents: initialValues.documents.filter(
                    (x: any) => x.objectUrl !== showDeleteConfirmation.objectUrl
                  ),
                },
                true
              );
            } else {
              handleEachStepNext(
                formName,
                {
                  ...formik.values,
                  documents: initialValues.documents.filter(
                    (x: any) => x.name !== showDeleteConfirmation.name
                  ),
                },
                true
              );
            }
          }
        }}
      />
    </form>
  );
}
