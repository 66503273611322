import * as profileType from "./types";

const types = {
  ...profileType,
};

export type ProfileType = {
  companyName: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  primaryPhoneNumber: string,
  secondaryPhoneNumber: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zipCode: string,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string
};

const initialState: ProfileType = {
  companyName: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  primaryPhoneNumber: '',
  secondaryPhoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  createdAt: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: ''
};

export default function profileReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.GET_PROFILE_SUCCESS:
      return {
        ...action.payload
      };
    case types.GET_PROFILE_ERROR:
      return initialState;
    default:
      return state;
  }
}
