import { Dialog } from "primereact/dialog";
import { IconButton, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { Button } from "primereact/button";

const DeleteConfirmationModal = (props: any) => {
  return (
    <Dialog
      visible={props.open}
      onHide={() => props.handleClose()}
      modal
      draggable={false}
      style={{
        width: "50%",
      }}
      contentStyle={{
        padding: 0,
        borderRadius: "5px",
      }}
      showHeader={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 2rem 0 2rem",
        }}
      >
        <Typography variant="h6">Delete Confirmation</Typography>

        <IconButton
          onClick={() => props.handleClose({ show: false, refreshPage: false })}
          style={{
            position: "absolute",
            right: "-1rem",
            top: "-1rem",
            backgroundColor: "#1769aa",
            color: "#fff",
          }}
        >
          <Clear color="inherit" />
        </IconButton>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "2rem",
          alignItems: "center",
        }}
      >
        <Typography>Are you sure you want to delete this file?</Typography>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0 3rem 2rem 2rem",
          justifyContent: "flex-end",
        }}
      >
        <Button
          label="No"
          icon="pi pi-times"
          style={{
            borderColor: "#d65c49",
            backgroundColor: "#d65c49",
          }}
          onClick={() => props.handleClose()}
        />

        <Button
          type="button"
          label="Yes"
          icon="pi pi-check"
          style={{
            marginLeft: "2rem",
            backgroundColor: "#1769aa",
          }}
          onClick={() => props.onDelete()}
        />
      </div>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
