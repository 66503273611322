import * as dashboardTypes from "./types";

const types = {
  ...dashboardTypes,
};

export type DashboardTpe = {
  drafts: number;
  submitted: number;
  pendingApproval: number;
  workOrdersInProgress: number;
};

const initialState: DashboardTpe = {
  drafts: 0,
  submitted: 0,
  pendingApproval: 0,
  workOrdersInProgress: 0,
};

export default function dashboardReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.SHOW_DASHBOARD_ERROR:
      return { ...initialState };
    case types.SHOW_DASHBOARD_SUCCESS:
      return {
        ...state,
        drafts: action?.payload?.drafts,
        submitted: action?.payload?.submitted,
        pendingApproval: action?.payload?.pendingApproval,
        workOrdersInProgress: action?.payload?.workOrdersInProgress,
      };
    default:
      return state;
  }
}
