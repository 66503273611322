import moment from "moment";

export const extractServiceRequestDataFromAPI = (data: any) => ({
  pickupDetails: {
    primaryContactFirstName:
      data?.pickupDetails?.primaryProfile?.firstName || "",
    primaryContactLastName: data?.pickupDetails?.primaryProfile?.lastName || "",
    primaryContactPhoneNumber:
      data?.pickupDetails?.primaryProfile?.primaryPhoneNumber || "",
    secondaryContactFirstName:
      data?.pickupDetails?.secondaryProfile?.firstName || "",
    secondaryContactLastName:
      data?.pickupDetails?.secondaryProfile?.lastName || "",
    secondaryContactPhoneNumber:
      data?.pickupDetails?.secondaryProfile?.primaryPhoneNumber || "",
    addressLine1: data?.pickupDetails?.primaryProfile?.addressLine1 || "",
    addressLine2: data?.pickupDetails?.primaryProfile?.addressLine2 || "",
    city: data?.pickupDetails?.primaryProfile?.city || "",
    state: data?.pickupDetails?.primaryProfile?.state || "",
    zipCode: data?.pickupDetails?.primaryProfile?.zipCode || "",
  },
  destinationDetails: {
    primaryContactFirstName:
      data?.destinationDetails?.primaryProfile?.firstName || "",
    primaryContactLastName:
      data?.destinationDetails?.primaryProfile?.lastName || "",
    primaryContactPhoneNumber:
      data?.destinationDetails?.primaryProfile?.primaryPhoneNumber || "",
    secondaryContactFirstName:
      data?.destinationDetails?.secondaryProfile?.firstName || "",
    secondaryContactLastName:
      data?.destinationDetails?.secondaryProfile?.lastName || "",
    secondaryContactPhoneNumber:
      data?.destinationDetails?.secondaryProfile?.primaryPhoneNumber || "",
    addressLine1: data?.destinationDetails?.primaryProfile?.addressLine1 || "",
    addressLine2: data?.destinationDetails?.primaryProfile?.addressLine2 || "",
    city: data?.destinationDetails?.primaryProfile?.city || "",
    state: data?.destinationDetails?.primaryProfile?.state || "",
    zipCode: data?.destinationDetails?.primaryProfile?.zipCode || "",
  },
  instructions: {
    generalInstructions: data?.pickupDetails?.generalInstructions || "",
    priorToPickupInstructions:
      data?.pickupDetails?.priorToPickupInstructions || "",
    onSiteInstructions: data?.pickupDetails?.onSiteInstructions || "",
    afterPickupInstructions: data?.pickupDetails?.afterPickupInstructions || "",
    documents: data?.documents,
  },
  shipmentDetails: {
    totalWeight: data?.shipmentDetails?.totalWeight || "",
    totalPalletsRequired: data?.shipmentDetails?.totalPalletsRequired || "",
    customOrderId: data?.customOrderId ? data?.customOrderId : data?.shipmentDetails?.customOrderId || "",
    itemsToDeliver: data?.shipmentDetails?.itemsToDeliver || "",
    wayBillNumber: data?.shipmentDetails?.wayBillNumber || "",
    shipperRefNumber: data?.shipmentDetails?.shipperRefNumber || "",
    billToRefNumber:data?.shipmentDetails?.billToRefNumber || "",
    scheduledDate: moment(data?.shipmentDetails?.scheduledDate).toDate() || "",
    scheduledTimeFrom: data?.shipmentDetails?.scheduledTimeFrom || null,
    scheduledTimeTo: data?.shipmentDetails?.scheduledTimeTo || null,
    laborsNeeded: data?.shipmentDetails?.laborsNeeded || "",
    laborTime: data?.shipmentDetails?.laborTime || "",
    noOfVehiclesNeeded: data?.shipmentDetails?.noOfVehiclesNeeded || "",
    vehicleType: data?.shipmentDetails?.vehicleType || "",
    serviceItems: data?.shipmentDetails?.serviceItems || [],
  },
  confirmation: false,
});
