import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { formatMoney } from "accounting";
import { Button } from "primereact/button";
import { TimePicker } from "@material-ui/pickers";

import { normalizeOnlyNums } from "../../../formUtils/normalizations";
import { getServiceItems } from "../../../services/serviceItems.service";
import ShipmentDetailsEditConfirmationDialog from "./ShipmentDetailsEditConfirmationDialog";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { Typography, Tooltip, IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataTableColumnStyle: {
      fontSize: "0.9rem",
      padding: "0.75rem !important",
      border: "1px solid #e9ecef !important",
    },
    dataTableHeaderStyle: {
      padding: "0.75rem !important",
      // border: "1px solid lightgrey !important",
      //backgroundColor: `${theme.palette.primary.main} !important`,
      //color: "#fff !important",
      whiteSpace: "nowrap",
    },
    dataTableRowStyle: {
      "&:nth-child(even)": {
        backgroundColor: "#BDBDBD !important",
      },
    },
  })
);

type ShipmentDetailsType = {
  totalWeight: string;
  totalPalletsRequired: string;
  itemsToDeliver: string;
  laborsNeeded?: string;
  laborTime?: string;
  noOfVehiclesNeeded?: string;
  vehicleType?: string;
  scheduledDate: any;
  scheduledTimeFrom: any;
  scheduledTimeTo: any;
  wayBillNumber: string;
  shipperRefNumber: string;
  billToRefNumber: string;
  customOrderId:string;
};

export default function ShipmentDetails({
  initialValues,
  formName,
  requireAllFields,
  currentFormRef,
  handleEachStepNext,
  readOnly,
  totalServiceCost,
  flowName,
}: any) {
  const [serviceItems, setServiceItems] = useState<any>([]);
  const [shouldDisabledEditing, setShouldDisabledEditing] =
    useState<any>(false);
  const [showEditDialog, setShowEditDialog] = useState<any>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<any>(false);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: initialValues || {
      totalWeight: "",
      totalPalletsRequired: "",
      itemsToDeliver: "",
      scheduledDate: "",
      scheduledTimeFrom: null,
      scheduledTimeTo: null,
      wayBillNumber: "",
      shipperRefNumber: "",
      billToRefNumber: "",
      customOrderId: "",
    },
    validate: (data: ShipmentDetailsType) => {
      let errors: any = {};

      if (!data.totalWeight) {
        errors.totalWeight = "This Field is required.";
      }

      if (!data.totalPalletsRequired) {
        errors.totalPalletsRequired = "This Field is required.";
      }

      if (!data.customOrderId) {
        errors.customOrderId = "This Field is required.";
      }

      // if (!data.scheduledDate) {
      //   errors.scheduledDate = "This Field is required.";
      // }

      // if (!data.scheduledTimeFrom) {
      //   errors.scheduledTimeFrom = "This Field is required.";
      // }

      // if (!data.scheduledTimeTo) {
      //   errors.scheduledTimeTo = "This Field is required.";
      // }

      if (data.scheduledTimeFrom && data.scheduledTimeTo) {
        const scheduledTimeTo = moment(data.scheduledTimeTo);
        const scheduledTimeFrom = moment(data.scheduledTimeFrom);
        if (
          Math.sign(
            moment.duration(scheduledTimeTo.diff(scheduledTimeFrom)).asHours()
          ) === -1
        ) {
          errors.scheduledTimeTo = "Please select a different time.";
        }
      }

      if (
        (
          serviceItems.find((x: any) => x.serviceName === "Labor") || {
            checked: false,
          }
        )?.checked
      ) {
        if (!data.laborsNeeded) {
          errors.laborsNeeded = "This Field is required.";
        }

        if (!data.laborTime) {
          errors.laborTime = "This Field is required.";
        }
      }

      if (
        (
          serviceItems.find((x: any) => x.serviceName === "Trucking") || {
            checked: false,
          }
        )?.checked
      ) {
        if (!data.noOfVehiclesNeeded) {
          errors.noOfVehiclesNeeded = "This Field is required.";
        }

        if (!data.vehicleType) {
          errors.vehicleType = "This Field is required.";
        }
      }

      if (requireAllFields) {
        if (!data.wayBillNumber) {
          errors.wayBillNumber = "This Field is required.";
        }

        if (!data.shipperRefNumber) {
          errors.shipperRefNumber = "This Field is required.";
        }

        if (!data.billToRefNumber) {
          errors.billToRefNumber = "This Field is required.";
        }
      }
      return errors;
    },
    onSubmit: (data: ShipmentDetailsType) => {
      handleEachStepNext(formName, {
        ...data,
        serviceItems,
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    (async function fetchAllServiceItems() {
      const serviceItemResponse = await getServiceItems();
      if (serviceItemResponse.status === 200) {
        const alreadyCheckedItems = [
          "Pickup",
          "Fuel",
          "Pack",
          "Labor",
          "Inside",
          "Cross dock",
        ];
        const serviceItemData = serviceItemResponse.data.map((data: any) => {
          const storedServiceItem = initialValues?.serviceItems?.find(
            (x: any) => x.id === data.id && x.checked
          );
          if (storedServiceItem) {
            return {
              ...data,
              ...storedServiceItem,
            };
          }

          return {
            ...data,
            checked: alreadyCheckedItems.includes(data.serviceName),
          };
        });
        setServiceItems(serviceItemData);
      } else {
        setServiceItems([]);
      }
    })();
    currentFormRef(formik);

    if (flowName === "Edit") {
      setShouldDisabledEditing(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type instructionsKeyType =
    | "totalWeight"
    | "totalPalletsRequired"
    | "itemsToDeliver"
    | "laborsNeeded"
    | "laborTime"
    | "noOfVehiclesNeeded"
    | "vehicleType"
    | "wayBillNumber"
    | "shipperRefNumber"
    | "billToRefNumber"
    | "scheduledDate"
    | "scheduledTimeFrom"
    | "scheduledTimeTo"
    | "customOrderId";

  const isFormFieldValid = (name: instructionsKeyType) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: instructionsKeyType) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const inputOneTemplate = (rowData: any) => {
    return (
      <>
        {rowData.serviceName === "Labor" && rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              marginTop: "0.75rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="laborsNeeded"
                name="laborsNeeded"
                value={formik.values.laborsNeeded}
                onChange={(e) => {
                  e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("laborsNeeded"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly || shouldDisabledEditing}
              />
              <label
                htmlFor="laborsNeeded"
                className={classNames({
                  "p-error": isFormFieldValid("laborsNeeded"),
                })}
              >
                Men*
              </label>
            </span>
          </div>
        ) : null}

        {rowData.serviceName === "Trucking" && rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              marginTop: "0.75rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="noOfVehiclesNeeded"
                name="noOfVehiclesNeeded"
                value={formik.values.noOfVehiclesNeeded}
                onChange={(e) => {
                  e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("noOfVehiclesNeeded"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly || shouldDisabledEditing}
              />
              <label
                htmlFor="noOfVehiclesNeeded"
                className={classNames({
                  "p-error": isFormFieldValid("noOfVehiclesNeeded"),
                })}
              >
                No Of Vehicles Needed*
              </label>
            </span>
          </div>
        ) : null}
      </>
    );
  };

  const inputTwoTemplate = (rowData: any) => {
    return (
      <>
        {rowData.serviceName === "Labor" && rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              marginTop: "0.75rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="laborTime"
                name="laborTime"
                value={formik.values.laborTime}
                onChange={(e) => {
                  e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("laborTime"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly || shouldDisabledEditing}
              />
              <label
                htmlFor="laborTime"
                className={classNames({
                  "p-error": isFormFieldValid("laborTime"),
                })}
              >
                Hours*
              </label>
            </span>
          </div>
        ) : null}

        {rowData.serviceName === "Trucking" && rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              marginTop: "0.75rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="vehicleType"
                name="vehicleType"
                value={formik.values.vehicleType}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleType"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly || shouldDisabledEditing}
              />
              <label
                htmlFor="vehicleType"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleType"),
                })}
              >
                Vehicle Type*
              </label>
            </span>
          </div>
        ) : null}

        {rowData.totalServiceCost ? (
          <span>{rowData.totalServiceCost}</span>
        ) : null}
      </>
    );
  };

  const serviceCostBody = (rowData: any) => {
    return (
      <>
        {rowData.checked && rowData.amount ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
            }}
          >
            <span className="p-float-label">
              <InputText
                id={`amountField${rowData.id}`}
                name={`amountField${rowData.id}`}
                value={formatMoney(Number(rowData.amount), "$", 2)}
                onChange={() => {
                  // e.target.value = normalizeOnlyNums(e.target.value);
                  // const newServiceItems = serviceItems.map((x: any) => {
                  //   if (x.id === rowData.id) {
                  //     return {
                  //       ...x,
                  //       amount: e.target.value,
                  //     };
                  //   }
                  //   return x;
                  // });
                  // setServiceItems(newServiceItems);
                }}
                className={classNames({
                  "p-invalid": !rowData.amount,
                })}
                style={{ width: "100%" }}
                disabled
              />
            </span>
          </div>
        ) : null}
      </>
    );
  };
  const InputComponentTimePicker = (props: any) => {
    return (
      <span className="p-float-label">
        <InputText
          className={classNames({
            "p-invalid": isFormFieldValid(props.name),
          })}
          style={{ width: "100%" }}
          {...props}
        />
        <label
          htmlFor={props.name}
          className={classNames({
            "p-error": isFormFieldValid(props.name),
          })}
        >
          {props.label}
        </label>
      </span>
    );
  };

  const ServiceNameTemplate = (props: any) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography>{props.serviceName}</Typography>

        <Tooltip
          open={isTooltipOpen === props.id}
          onClose={() => {}}
          arrow
          title={
            <Typography
              gutterBottom
              style={{ margin: "0.5rem" }}
              variant="body1"
            >
              {props.serviceDescription}
            </Typography>
          }
        >
          <IconButton
            onClick={() => {
              setIsTooltipOpen((prevState: any) =>
                prevState === props.id ? false : props.id
              );
            }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };
  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ width: "100%", fontSize: "0.85rem", paddingBottom: "2rem" }}
    >
      {flowName === "Edit" && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            marginBottom: "2rem",
          }}
        >
          <Button
            type="button"
            label={"Edit"}
            icon="pi pi-pencil"
            style={{
              backgroundColor: "#d65c49",
              borderColor: "#d65c49",
              marginLeft: "2rem",
            }}
            onClick={() => {
              setShowEditDialog(true);
            }}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="p-field"
          style={{
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <span className="p-float-label">
            <InputText
              id="customOrderId"
              name="customOrderId"
              value={formik.values.customOrderId}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              className={classNames({
                "p-invalid": isFormFieldValid("customOrderId"),
              })}
              style={{ width: "100%" }}
              disabled={readOnly || shouldDisabledEditing}
            />
            <label
              htmlFor="customOrderId"
              className={classNames({
                "p-error": isFormFieldValid("customOrderId"),
              })}
            >
              Custom Order ID*
            </label>
          </span>
          {getFormErrorMessage("customOrderId")}
        </div>

        <div
          className="p-field"
          style={{
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <span className="p-float-label">
            <InputText
              id="totalWeight"
              name="totalWeight"
              value={formik.values.totalWeight}
              onChange={(e) => {
                e.target.value = normalizeOnlyNums(e.target.value);
                formik.handleChange(e);
              }}
              className={classNames({
                "p-invalid": isFormFieldValid("totalWeight"),
              })}
              style={{ width: "100%" }}
              disabled={readOnly || shouldDisabledEditing}
            />
            <label
              htmlFor="totalWeight"
              className={classNames({
                "p-error": isFormFieldValid("totalWeight"),
              })}
            >
              Total Shipment Weight (in Lbs)*
            </label>
          </span>
          {getFormErrorMessage("totalWeight")}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <InputText
              id="totalPalletsRequired"
              name="totalPalletsRequired"
              value={formik.values.totalPalletsRequired}
              onChange={(e) => {
                e.target.value = normalizeOnlyNums(e.target.value);
                formik.handleChange(e);
              }}
              className={classNames({
                "p-invalid": isFormFieldValid("totalPalletsRequired"),
              })}
              style={{ width: "100%" }}
              disabled={readOnly || shouldDisabledEditing}
            />
            <label
              htmlFor="totalPalletsRequired"
              className={classNames({
                "p-error": isFormFieldValid("totalPalletsRequired"),
              })}
            >
              Total Pallets Required*
            </label>
          </span>
          {getFormErrorMessage("totalPalletsRequired")}
        </div>
      </div>

      {/* {requireAllFields && ( */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="p-field"
          style={{
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <span className="p-float-label">
            <Calendar
              id="scheduledDate"
              name="scheduledDate"
              value={formik.values.scheduledDate}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("scheduledDate"),
              })}
              style={{ width: "100%" }}
              monthNavigator
              yearNavigator
              showIcon
              disabled={readOnly || shouldDisabledEditing}
              yearRange={`${moment().toDate().getFullYear()}:${moment()
                .add(20, "year")
                .toDate()
                .getFullYear()}`}
            />
            <label
              htmlFor="scheduledDate"
              className={classNames({
                "p-error": isFormFieldValid("scheduledDate"),
              })}
            >
              Scheduled Date*
            </label>
          </span>
          {/* {getFormErrorMessage("scheduledDate")} */}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <TimePicker
              id="scheduledTimeFrom"
              name="scheduledTimeFrom"
              label="Time From"
              defaultValue={null}
              disabled={readOnly || shouldDisabledEditing}
              TextFieldComponent={InputComponentTimePicker}
              value={formik.values.scheduledTimeFrom || null}
              onChange={(event: any) => {
                formik.setFieldValue("scheduledTimeFrom", event);
                const scheduledTimeTo = moment(formik.values.scheduledTimeTo);
                const scheduledTimeFrom = moment(
                  formik.values.scheduledTimeFrom
                );
                if (
                  formik.values.scheduledTimeTo &&
                  Math.sign(
                    moment
                      .duration(scheduledTimeTo.diff(scheduledTimeFrom))
                      .asHours()
                  ) === -1
                ) {
                  formik.setFieldValue("scheduledTimeTo", event);
                }
              }}
            />
            {/* <InputMask
              id="scheduledTimeFrom"
              name="scheduledTimeFrom"
              value={formik.values.scheduledTimeFrom}
              onChange={(e: any) => {
                // e.target.value = normalizeOnlyNums(e.target.value);
                // formik.handleChange(e);
              }}
              // className={classNames({
              //   "p-invalid": isFormFieldValid("totalPalletsRequired"),
              // })}
              style={{ width: "100%" }}
              disabled
              mask="99:99"
              // timeOnly
              // showTime
              // hourFormat="12"
            />
            <label
              htmlFor="scheduledTimeFrom"
              // className={classNames({
              //   "p-error": isFormFieldValid("scheduledTimeFrom"),
              // })}
            >
              Time From*
            </label> */}
          </span>
          {getFormErrorMessage("scheduledTimeFrom")}
        </div>
        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <TimePicker
              id="scheduledTimeTo"
              name="scheduledTimeTo"
              label="Time To"
              disabled={readOnly || shouldDisabledEditing}
              defaultValue={null}
              TextFieldComponent={InputComponentTimePicker}
              value={formik.values.scheduledTimeTo || null}
              onChange={(event: any) =>
                formik.setFieldValue("scheduledTimeTo", event)
              }
            />
            {/* <InputMask
              id="scheduledTimeTo"
              name="scheduledTimeTo"
              value={formik.values.scheduledTimeTo}
              onChange={(e: any) => {
                // e.target.value = normalizeOnlyNums(e.target.value);
                // formik.handleChange(e);
              }}
              // className={classNames({
              //   "p-invalid": isFormFieldValid("totalPalletsRequired"),
              // })}
              style={{ width: "100%" }}
              disabled
              mask="99:99"
              // timeOnly
              // showTime
              // hourFormat="12"
            />
            <label
              htmlFor="scheduledTimeTo"
              // className={classNames({
              //   "p-error": isFormFieldValid("scheduledTimeTo"),
              // })}
            >
              Time To*
            </label> */}
          </span>
          {/* {getFormErrorMessage("scheduledTimeTo")} */}
        </div>
      </div>
      {/* )} */}

      {/* <div className="p-field" style={{ width: "100%" }}>
        <span className="p-float-label">
          <InputTextarea
            id="itemsToDeliver"
            name="itemsToDeliver"
            value={formik.values.itemsToDeliver}
            onChange={formik.handleChange}
            className={classNames({
              "p-invalid": isFormFieldValid("itemsToDeliver"),
            })}
            autoResize
            style={{
              width: "100%",
              maxHeight: "5rem",
              overflowX: "hidden",
              overflowY: "auto",
              scrollbarWidth: "thin",
            }}
            disabled={readOnly || shouldDisabledEditing}
          />
          <label
            htmlFor="itemsToDeliver"
            className={classNames({
              "p-error": isFormFieldValid("itemsToDeliver"),
            })}
          >
            Shipment Description{requireAllFields ? "*" : ""}
          </label>
        </span>
        {getFormErrorMessage("itemsToDeliver")}
      </div> */}

      <DataTable
        loading={!serviceItems.length}
        value={
          // requireAllFields
          //   ? [
          //       ...serviceItems,
          //       {
          //         totalServiceCost: "Total Service Cost: ",
          //         amount: totalServiceCost,
          //         checked: true,
          //       },
          //     ]
          //   :
          serviceItems
        }
        sortMode="multiple"
        dataKey="id"
        resizableColumns
        columnResizeMode="expand"
        tableStyle={{
          position: "relative",
          tableLayout: "auto",
          overflow: "auto",
          width: "100%",
        }}
        selection={serviceItems.filter((x: any) => x.checked)}
        onSelectionChange={(e) => {
          // do not let user change if readOnly || shouldDisabledEditing true
          if (readOnly || shouldDisabledEditing) {
            return;
          }

          let newServiceItemsArray: any = [...serviceItems];

          newServiceItemsArray = newServiceItemsArray.map((x: any) => {
            if (e.value?.some((y: any) => y.id === x.id)) {
              return {
                ...x,
                checked: true,
              };
            } else {
              return {
                ...x,
                checked: [
                  "Pickup",
                  "Fuel",
                  "Pack",
                  "Labor",
                  "Inside",
                  "Cross dock",
                ].includes(x.serviceName)
                  ? true
                  : false,
              };
            }
          });

          setServiceItems(newServiceItemsArray);
        }}
      >
        <Column
          selectionMode="multiple"
          style={{ width: "3rem" }}
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
        />
        <Column
          field="serviceName"
          header="Service Name"
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
          body={ServiceNameTemplate}
        ></Column>
        <Column
          field=""
          header=""
          body={inputOneTemplate}
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
        ></Column>
        <Column
          field="totalServiceCost"
          header=""
          body={inputTwoTemplate}
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
        ></Column>
        {/* <Column
          field="serviceCost"
          header="Service Cost"
          body={serviceCostBody}
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
        ></Column> */}
      </DataTable>
      {requireAllFields && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "flex-end",
            border: "1px solid #e9ecef",
            padding: "0.75rem",
            fontSize: "0.9rem",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
            }}
          ></div>
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
            }}
          ></div>
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            Total Service Cost:{" "}
            <b>{formatMoney(Number(totalServiceCost || 0), "$", 2)}</b>
            {/* <span className="p-float-label">
              <InputText
                  value={formatMoney(Number(totalServiceCost || 0), "$", 2)}
                  onChange={() => {}}
                  className={classNames({
                    "p-invalid": !totalServiceCost,
                  })}
                  style={{ width: "100%" }}
                  disabled
              />
            </span> */}
          </div>
        </div>
      )}

      {requireAllFields && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2rem",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              marginRight: "0.5rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="wayBillNumber"
                name="wayBillNumber"
                value={formik.values.wayBillNumber}
                onChange={(e) => {
                  // e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("wayBillNumber"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly}
              />
              <label
                htmlFor="wayBillNumber"
                className={classNames({
                  "p-error": isFormFieldValid("wayBillNumber"),
                })}
              >
                Waybill #*
              </label>
            </span>
            {getFormErrorMessage("wayBillNumber")}
          </div>

          <div
            className="p-field"
            style={{ width: "100%", marginLeft: "0.5rem" }}
          >
            <span className="p-float-label">
              <InputText
                id="shipperRefNumber"
                name="shipperRefNumber"
                value={formik.values.shipperRefNumber}
                onChange={(e) => {
                  // e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("shipperRefNumber"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly}
              />
              <label
                htmlFor="shipperRefNumber"
                className={classNames({
                  "p-error": isFormFieldValid("shipperRefNumber"),
                })}
              >
                Shipper Ref #*
              </label>
            </span>
            {getFormErrorMessage("shipperRefNumber")}
          </div>
          <div
            className="p-field"
            style={{ width: "100%", marginLeft: "0.5rem" }}
          >
            <span className="p-float-label">
              <InputText
                id="billToRefNumber"
                name="billToRefNumber"
                value={formik.values.billToRefNumber}
                onChange={(e) => {
                  // e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("billToRefNumber"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly}
              />
              <label
                htmlFor="billToRefNumber"
                className={classNames({
                  "p-error": isFormFieldValid("billToRefNumber"),
                })}
              >
                Bill-To #*
              </label>
            </span>
            {getFormErrorMessage("billToRefNumber")}
          </div>
        </div>
      )}

      <ShipmentDetailsEditConfirmationDialog
        open={showEditDialog}
        handleClose={() => {
          setShowEditDialog(false);
        }}
        handleContinue={() => {
          setShouldDisabledEditing(false);
          setShowEditDialog(false);
        }}
      />
    </form>
  );
}
