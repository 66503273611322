import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to fetch dashboard items
 * @returns
 */
export const getOrderData = async (
    searchText: string,
    pageNumber: number,
    pageSize: number,
    filters: string
) => {
    try {
        return await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/work-order/get-customer-work-orders?page_number=${pageNumber}&page_size=${pageSize}&search_term=${searchText || ""}&filters=${filters ||''}`);
    } catch (error: any) {
        return {
          status: 500,
          data: null,
          statusText: 'Oops! something went wrong'
        }
    }
};

/**
 * This function is used to fetch work order items by id
 * @returns
 */
 export const getOrderDataByID = async (
    orderID: string
  ) => {
      try {
          return await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/work-order/get?order_id=${orderID}`);
      } catch (error: any) {
          return {
            status: 500,
            data: null,
            statusText: 'Oops! something went wrong'
          }
      }
  };
  /**
   * This function is used to fetch work order items timeline
   * @returns
   */
  export const getOrderTimeline = async (
    orderID: string
  ) => {
      try {
          return await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/work-order/get-timeline-data?order_id=${orderID}`);
      } catch (error: any) {
          return {
            status: 500,
            data: null,
            statusText: 'Oops! something went wrong'
          }
      }
  };
  
  
/**
 * This function is used to fetch asset recovery worksheet.
 * @returns
 */
 export const getAssetRecoveryWorksheet = async (orderID: string) => {
    try {
        return await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/assets/asset-recovery-work-sheet?order_id=${orderID}`, {responseType: 'arraybuffer'});
    } catch (error: any) {
        return {
          status: 500,
          data: null,
          statusText: 'Oops! something went wrong'
        }
    }
  };
  
  