import axios from "axios";
import { getAuth, signInWithEmailAndPassword, signOut, updatePassword } from "firebase/auth";

/**
 * This function is used to login a user.
 * @returns
 */
export const loginUser = async (payload: {
  email: string;
  password: string;
}) => {
  try {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(
      auth,
      payload?.email,
      payload?.password
    );
    return {
      status: 200,
      data: userCredential,
      statusText: "User successfully logged in",
    };
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: error.code || "Oops! something went wrong",
    };
  }
};

/**
 * This function is used to logout a user.
 * @returns
 */
export const logoutUser = async () => {
    try {
        const auth = getAuth();
        await signOut(auth);
        localStorage.removeItem('id_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        return  {status: 200, data: null, statusText: 'Signout successful'};
    } catch (error: any) {
        return {
          status: 500,
          data: null,
          statusText: error.code || 'Oops! something went wrong'
        }
    }
}


/**
 * This function is used to update a user password.
 * @returns 
 */
 export const changePassword = async (payload: {newPassword:string}) => {
  try {
      const auth = getAuth();
      const userCredential = await updatePassword((auth as any).currentUser, payload?.newPassword)
      return {status: 200, data: userCredential,statusText: 'Password updated successfully'};
  } catch (error: any) {
      return {status: 500, data: null, statusText: error.code || 'Oops! something went wrong'}
  }
};

/**
* This function is used to logout a user.
* @returns 
*/
export const refreshAccessToken = async () => {
    try {
        const endPoint = `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`;
        const firebaseResponse = await axios.post(endPoint, {grant_type: 'refresh_token', refresh_token:localStorage.getItem('refresh_token') || ''});
        localStorage.setItem('id_token', firebaseResponse.data.id_token);
        localStorage.setItem('refresh_token', firebaseResponse.data.refresh_token);
        return firebaseResponse.data.id_token;
    } catch (error) {
        console.error(error);
    }
}
