import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  InputBase,
  Button,
  Link,
  TablePagination,
  Popper,
  Grow,
  ClickAwayListener,
  Modal,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  DateRange as DateRangeIcon,
} from "@material-ui/icons";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import { DateRangePicker } from "materialui-daterange-picker";

import { changeSelectedOrder } from "../../../redux/orders/actions";
import TimeLine from "../History/Timeline";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {},
    paper: {
      padding: theme.spacing(2),
    },
    outlinedButtonStyle: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
      },
    },
    buttonContainerStyle: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    search: {
      position: "relative",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    rowStyle: {
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    linkStyle: {
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    timelineContainer: {
      position: "absolute",
      width: "50%",
      padding: theme.spacing(2),
      maxHeight: "90%",
      overflow: "scroll",
    },
  })
);

type OrdersProps = {
  history: {
    push: Function;
  };
};

export default function InProgress({ history }: OrdersProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const statusAllowed = [
    "Quotation Approved",
    "Crew Assigned",
    "Crew ready for pick up",
    "Order Picked up",
    "Delivered",
  ];
  const orders = useSelector((state: any) =>
    state.orders.list.filter((x: any) => statusAllowed.includes(x.status))
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listOfOrders, _] = useState(orders);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(moment().subtract(30, "days").calendar()),
    endDate: new Date(),
  });
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isTimeLineOpen, setIsTimeLineOpen] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const _handleOrderNumberClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    orderNumber: String
  ) => {
    event.preventDefault();

    dispatch(changeSelectedOrder(orderNumber));

    history.push("/order-summary");
  };

  const handleDateRangeToggle = () => {
    setIsDateRangePickerOpen((value) => !value);
  };

  const handleDateRangeClose = (e: any) => {
    e.preventDefault();
    setIsDateRangePickerOpen(false);
  };

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={3}>
        <Grid item sm={10}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={() => {}}
            />
          </div>
        </Grid>
      </Grid>

      <Grid item sm={7}>
        <Button
          variant="outlined"
          className={classes.outlinedButtonStyle}
          endIcon={<DateRangeIcon style={{ fontSize: "24px" }} />}
          onClick={handleDateRangeToggle}
          ref={anchorRef}
        >
          {`${moment(dateRange?.startDate).format("MM/DD/YYYY")} - ${moment(
            dateRange?.endDate
          ).format("MM/DD/YYYY")}`}
        </Button>

        <Popper
          open={isDateRangePickerOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={(e: any) => handleDateRangeClose(e)}
                >
                  <DateRangePicker
                    open={true}
                    toggle={handleDateRangeToggle}
                    onChange={(range: any) => setDateRange(range)}
                    maxDate={new Date()}
                  />
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>

      <Grid item sm={2} className={classes.buttonContainerStyle}>
        <Button variant="contained" color="primary">
          Export
        </Button>
      </Grid>

      <Grid item sm={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="orders table">
            <TableHead>
              <TableRow>
                <TableCell>Order Number</TableCell>
                <TableCell align="left">Pick Up Location</TableCell>
                <TableCell align="left">Drop Off Location</TableCell>
                <TableCell align="left">Created On</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfOrders.map((row: any) => (
                <TableRow key={row.id} className={classes.rowStyle}>
                  <TableCell component="th" scope="row">
                    <Link
                      className={classes.linkStyle}
                      onClick={(
                        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                      ) => _handleOrderNumberClick(event, row.orderNumber)}
                    >
                      {row.orderNumber}
                    </Link>
                  </TableCell>
                  <TableCell align="left">{`${
                    row?.pickupDetails?.addressline1
                  }, ${row?.pickupDetails?.addressline2 + ", " || ""} ${
                    row?.pickupDetails?.city
                  }, ${row?.pickupDetails?.state} ${
                    row?.pickupDetails?.zip
                  }`}</TableCell>
                  <TableCell align="left">{`${
                    row?.dropoffDetails?.addressline1
                  }, ${row?.dropoffDetails?.addressline2 + ", " || ""} ${
                    row?.dropoffDetails?.city
                  }, ${row?.dropoffDetails?.state} ${
                    row?.dropoffDetails?.zip
                  }`}</TableCell>
                  <TableCell align="left">{`${moment(row.createdOn).format(
                    "L"
                  )} ${moment(row.createdOn).format("LT")}`}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">
                    <Link
                      className={classes.linkStyle}
                      onClick={(
                        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                      ) => {
                        event.preventDefault();
                        setIsTimeLineOpen(true);
                      }}
                    >
                      Timeline
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={listOfOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>

      <Modal
        open={isTimeLineOpen}
        onClose={() => setIsTimeLineOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper className={classes.timelineContainer}>
          <TimeLine />
        </Paper>
      </Modal>
    </Grid>
  );
}
