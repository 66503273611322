export const validateRequired = (value: String) =>
  value ? undefined : "This field is required";

export const validateZipCode = (value: String) => {
  if (!value) {
    return;
  }

  if (value && (value.length < 5 || (value.length > 5 && value.length < 9))) {
    return "Please enter a valid zip code. e.g. 12345 or 12345-6789";
  }

  return;
};
